import styles from "./HeroBackground.module.scss";
import TranslationBackgroundTexture from "../Icons/TranslationBackgroundTexture";

const HeroBackground = () => (
  <div className={styles.HeroBackgroundContainer}>
    <TranslationBackgroundTexture />
  </div>
);

export default HeroBackground;
