import React from "react";
import clsx from "clsx";

const Text = ({ as, className, children, ...props }) => {
  const AsElement = as ?? "span";
  return (
    <AsElement {...props} className={clsx(className)}>
      {children}
    </AsElement>
  );
};

export default Text;
