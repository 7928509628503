import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { PopupButton } from "react-calendly";
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';

import { useCookies } from 'react-cookie';
import axios from "@/common/libs/axios";
import UserContext from "@/context/UserContext";
import BlackUploadIcon from "@/assets/icons/UploadIcon";
import { useContext, useEffect, useRef, useState } from "react";
import MailOutlineRoundedIcon from '@mui/icons-material/MailOutlineRounded';
// Module

import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import EnterpriseLayout from "@/common/components/elements/Layout/EnterpriseLayout.js";

export default function AccountInfo() {
  const { user } = useAuth({
    middleware: "auth",
  });
  const usercontext = useContext(UserContext);
  const isTeamAdmin = usercontext.user?.team_admin;
  const allowedFormats = usercontext.allowedFormats;
  const [rootDiv, setRootDiv] = useState("");

  const refImg = useRef(null)
  const [cookies, setCookie] = useCookies(['cookie-name']);

  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const isArabic = locale === "ar";
  const [planDetails, setPlanDetails] = useState(null);
  const [loadingPlanDetails, setLoadingPlanDetails] = useState(true);

  
  const [userInfo, setUserInfo] = useState({
    name: usercontext.user?.name || '',
    email: usercontext.user?.email || ''
  });
  

  const [companyInfo, setCompanyInfo] = useState({
    name: '',
    company_logo: ''
  });

  const updateTeamInfo = () => {

    const file = refImg.current.files[0]
    const formData = new FormData()
    if (isTeamAdmin) {
      formData.append("company_logo", file);
      formData.append("team_name", companyInfo.name);
    }
    formData.append("user_name", userInfo.name);
    formData.append("email", userInfo.email);
    axios
      .post("/api/user/team/settings", formData)
      .then((res) => {
      })
      .catch((error) => {

      })
  };
  const onCompanyInfoChange = (field, data) => {
    if (field === "company_logo") {
      const file = data[0]
      const render = new FileReader()
      render.onload = (event) => {
        setCompanyInfo({ ...companyInfo, [field]: event.target.result });
      }
      render.readAsDataURL(file)
    } else if (field === "name") {
      setCompanyInfo({ ...companyInfo, [field]: data });
    }
  }
  const onUserInfoChange = (field, value) => {
    setUserInfo({ ...userInfo, [field]: value });
  }
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openpopover = Boolean(anchorEl);

  useEffect(() => {
    if (document) {
      var root = document.getElementById("__next");
      setRootDiv(root);
    }
    if (isTeamAdmin) {
      axios
        .get("/api/user/team/subscription")
        .then((res) => {
          setPlanDetails(res.data);
          setLoadingPlanDetails(false);
        })
        .catch((error) => {
          setPlanDetails(null);
          setLoadingPlanDetails(false);
          if (error?.response?.status !== 409) throw error;
        });
    }

    axios
      .get("/api/user/team-info")
      .then((res) => {
        setCompanyInfo({ ...companyInfo, ['name']: res.data.name, ["company_logo"]: res.data.company_logo });
        setLoadingPlanDetails(false);
      })
      .catch((error) => {
        setLoadingPlanDetails(false);
      });
      

    if (usercontext.user) {
      setUserInfo({
        name: usercontext.user.name,
        email: usercontext.user.email
      });
    }
  }, [usercontext.user]); 
  return (
    <EnterpriseLayout
      usr={user}
      className="mb-[50px] md:mb-[140px] lg:mb-[160px] xl:mb-[175px]"
      title="Tarjama AMT - The best Arabic translation tool"
      description="Best online translator - translate your text and document with Tarjama translator"
    >
      <div className="flex flex-col relative">
        <div className={`justify-between items-center mb-20 ${!isTeamAdmin ? 'hidden' : 'flex'}`}>
          <p className="font-semibold text-lg">{t.AccountInfo}</p>
          <div className="flex items-center cursor-pointer">
            <MailOutlineRoundedIcon color="black" className="mr-5 cursor-pointer" />
            <PopupButton
              url={process.env.NEXT_PUBLIC_CALENDLY_MEETING_LINK}
              rootElement={rootDiv}
              text={t.ContactSales}
              className={`font-semibold text-black flex items-center cursor-pointer`}
            />
          </div>

          {/*<div className="flex items-center cursor-pointer" onClick={() => contactSales()}>
            <MailOutlineRoundedIcon color="black" className="mr-5 cursor-pointer" />
            <p className='font-semibold text-black'>{t.ContactSales}</p>
          </div>*/}
        </div>
        <div className={`w-full flex flex-col md:flex-row gap-5 pb-5 border-b ${!isTeamAdmin ? 'hidden' : 'flex'}`}>
          <div className="flex border rounded border-grey200 p-5 justify-between w-full md:w-1/3 flex-col gap-1 md:gap-3" >
            <p className="text-black font-semibold text-ml">{t.PlanDetails}</p>
            <div className="w-full flex items-center justify-between flex-nowrap gap-5 md:flex-wrap md:gap-0">
              <div className="flex flex-col w-1/2 md:w-1/3">
                <p className="text-grey800 text-md">{t.StartDate}</p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">{planDetails?.subscribed_date || "-"}</p>
                }
              </div>
              <div className="flex flex-col w-1/2 md:w-1/3">
                <p className="text-grey800 text-md">{t.ExpiryDate}</p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">{planDetails?.date_unsubscribed || "-"}</p>
                }
              </div>
              <div className="flex flex-col w-1/2 md:w-1/3">
                <p className="text-grey800 text-md">{t.Frequency}</p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">-</p>
                }
              </div>
            </div>
          </div>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={openpopover}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <div className="w-full flex gap-0 flex-wrap md:flex-nowrap flex-col p-3 w-72">
              <div className="flex justify-between w-full mb-2">
                <div className="flex flex-col w-1/2">
                  <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-lightblue ${isArabic ? 'ml-1' : 'mr-1'}`} />{t.Files}</p>
                  <p className="text-black font-semibold text-sm">{planDetails?.total_used_files}</p>
                </div>
                <div className="flex flex-col w-1/2 items-end md:items-start">
                  <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-green ${isArabic ? 'ml-1' : 'mr-1'}`} />{t.TextWords}</p>
                  <p className="text-black font-semibold text-sm">{planDetails?.total_used_words_text_translation}</p>
                </div>
              </div>
              <div className="flex justify-between w-full mb-2">
                <div className="flex flex-col w-1/2">
                  <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-yellow ${isArabic ? 'ml-1' : 'mr-1'}`} /><span>{t.DocumentWords}</span></p>
                  <p className="text-black font-semibold text-sm">{planDetails?.total_used_words_document_translation}</p>
                </div>
                <div className="flex flex-col w-1/2 items-end md:items-start">
                  <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-red ${isArabic ? 'ml-1' : 'mr-1'}`} />{t.Pages}</p>
                  <p className="text-black font-semibold text-sm">{planDetails?.total_used_pages_website_extraction}</p>
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="flex flex-col w-1/2">
                  <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-pink-500 ${isArabic ? 'ml-1' : 'mr-1'}`} /><span>{t.DocumentsChars}</span></p>
                  <p className="text-black font-semibold text-sm">{planDetails?.total_used_char_text_translation}</p>
                </div>
              </div>
            </div>
          </Popover>
          <div className="flex border rounded border-grey200 p-5 justify-between w-full md:w-2/3 flex-col gap-3">
            <div className="flex justify-between items-center">
              <p className="text-black font-semibold text-ml">{t.Quota}</p>
              <div className="w-2/3 md:w-1/4 h-3 rounded-full"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{
                  background: `linear-gradient(to ${isArabic ? 'left' : 'right'},    
                  #3CB9D6 ${planDetails?.total_used_files_percentage},    
                  #00A499 ${planDetails?.total_used_files_percentage} ${planDetails?.total_used_words_text_translation_percentage},    
                  #FBC46E ${planDetails?.total_used_words_text_translation_percentage}  ${planDetails?.total_used_words_document_translation_percentage},   
                   #F16D78 ${planDetails?.total_used_words_document_translation_percentage} ${planDetails?.total_used_pages_website_extraction_percentage}, 
                   #ec489d ${planDetails?.total_used_pages_website_extraction_percentage} ${planDetails?.total_used_char_text_translation_percentage} , 
                   #D1D2D4 ${planDetails?.total_used_char_text_translation_percentage} )`
                }} />
            </div>
            <div className="w-full flex items-center justify-between flex-row  gap-0 flex-wrap md:flex-nowrap md:gap-5">
              <div className="flex flex-col w-1/2 md:w-fit">
                <p className="text-grey800 flex items-center text-md"><span className={`h-3 w-3 rounded-full bg-lightblue ${isArabic ? 'ml-2' : 'mr-2'}`} />{t.Files}</p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">{planDetails?.total_used_files}</p>
                }
              </div>
              <div className="flex flex-col w-1/2 md:w-fit">
                <p className="text-grey800 flex items-center text-md"><span className={`h-3 w-3 rounded-full bg-green ${isArabic ? 'ml-2' : 'mr-2'}`} /><span>{t.TextWords}</span></p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">{planDetails?.total_used_words_text_translation}</p>
                }
              </div>
              <div className="flex flex-col w-1/2 md:w-fit">
                <p className="text-grey800 flex items-center text-md"><span className={`h-3 w-3 rounded-full bg-yellow ${isArabic ? 'ml-2' : 'mr-2'}`} /><span>{t.DocumentWords}</span></p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">{planDetails?.total_used_words_document_translation}</p>
                }
              </div>
              <div className="flex flex-col w-1/2 md:w-fit">
                <p className="text-grey800 flex items-center text-md"><span className={`h-3 w-3 rounded-full bg-red ${isArabic ? 'ml-2' : 'mr-2'}`} /><span>{t.Pages}</span></p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">{planDetails?.total_used_pages_website_extraction}</p>
                }
              </div>
              <div className="flex flex-col w-1/2 md:w-fit">
                <p className="text-grey800 flex items-center text-md"><span className={`h-3 w-3 rounded-full bg-pink-500 ${isArabic ? 'ml-2' : 'mr-2'}`} /><span className="whitespace-nowrap">{t.DocumentsChars}</span></p>
                {loadingPlanDetails ?
                  <Skeleton variant="text mr-5" sx={{ fontSize: '1rem' }} />
                  : <p className="text-black font-semibold text-sm md:text-mm">{planDetails?.total_used_char_text_translation}</p>
                }
              </div>
            </div>
          </div>
        </div>
        <div className={`flex flex-col ${isTeamAdmin ? 'mt-20' : ''}`}>
          <p className="text-black font-semibold text-ml mb-20">{t.PersonalInfo}</p>
          <div className="flex justify-between items-center w-full gap-5 flex-col md:flex-row">
            <div className="w-full md:w-1/2 flex flex-col gap-3">
              <label className="block text-black text-md">{t.Name}<span className="text-red">*</span></label>
              <TextField
                disabled={loadingPlanDetails}
                id="outlined-required"
                value={userInfo.name}
                onChange={(event) => onUserInfoChange("name", event.target.value)}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col gap-3">
              <label className="block text-black text-md">{t.Email}<span className="text-red">*</span></label>
              <TextField
                id="outlined-required"
                value={userInfo.email}
                disabled
                onChange={(event) => onUserInfoChange("email", event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className={`flex-col mt-20 ${!isTeamAdmin ? 'hidden' : 'flex'}`}>
          <p className="text-black font-semibold text-ml mb-20">{t.CompanyInfo}</p>
          <div className="flex justify-between items-center w-full gap-5 flex-col md:flex-row">
            <div className="w-full md:w-1/2 flex flex-col gap-3">
              <label className="block text-black text-md">{t.Name}</label>
              <TextField
                disabled={loadingPlanDetails}
                id="outlined-required"
                value={companyInfo.name}
                onChange={(event) => onCompanyInfoChange("name", event.target.value)}
              />
            </div>
            <div className="w-full md:w-1/2 flex flex-col gap-3">
              <label className="block text-black text-md">{t.CompanyLogo}</label>
              {loadingPlanDetails ?
                <Skeleton variant="circular" width={70} height={70} /> :
                <div className="flex items-center justify-between">
                  <img src={companyInfo.company_logo} alt={t.CompanyLogo} width="100" height="50" />
                  <label>
                    <span
                      className={`flex flex-row gap-3 text-base leading-normal font-semibold ${loadingPlanDetails ? 'cursor-not-allowed text-grey' : 'cursor-pointer text-green'}`}
                    >
                      <BlackUploadIcon color={loadingPlanDetails ? "grey" : "#00A499"} />
                      {t.browseBtnText}
                    </span>
                    <input
                      type="file"
                      ref={refImg}
                      className="hidden"
                      accept="image/png, image/jpeg"
                      onChange={(e) => {
                        onCompanyInfoChange('company_logo', e.target.files);
                      }}
                    />
                  </label>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <Box className="flex justify-end">
        <div className="flex items-center justify-center md:justify-end relative md:absolute bottom-0 mt-20 md:mb-20 md:mt-0">
          <Button variant="outlined" className={`btn-outline-grey text-normal m-0 ${isArabic ? 'ml-5' : 'mr-5'}`}>{t.Cancel}</Button>
          <Button variant="contained" className="btn-green text-normal m-0" onClick={updateTeamInfo} disabled={!userInfo.name || !userInfo.email}>{t.SaveEdits}</Button>
        </div>
      </Box>
    </EnterpriseLayout >
  );
}
