import traslationTexture from "@/assets/images/Background.png";
const TranslationBackgroundTexture = () => {
  return (
    <div
      style={{
        backgroundImage: "url(" + traslationTexture.src + ")",
        backgroundSize: "cover",
        width: "100%",
      }}
    ></div>
  );
};

export default TranslationBackgroundTexture;
