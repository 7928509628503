import Link from "next/link";
import { Menu } from "@headlessui/react";
import { useContext } from "react";
import AppContext from "../../../../context/AppContext";

const DropdownLink = ({ children, ...props }) => {
  const appcontext = useContext(AppContext);
  const locale = appcontext.locale;
  return (
    <Menu.Item>
      {({ active }) => (
        <Link {...props}>
          <a
            className={`w-full ${
              locale == "ar" ? "text-right" : "text-left"
            } block px-4 py-2 text-sm leading-5 text-gray-700 ${
              active ? "bg-gray-100" : ""
            } focus:outline-none transition duration-150 ease-in-out hover:bg-gray-100 font-medium`}
          >
            {children}
          </a>
        </Link>
      )}
    </Menu.Item>
  );
};

export const DropdownButton = ({ children, ...props }) => {
  const appcontext = useContext(AppContext);
  const locale = appcontext.locale;
  return (
    <Menu.Item>
      {({ active }) => (
        <button
          className={`w-full ${
            locale == "ar" ? "text-right" : "text-left"
          } block px-4 py-2 text-sm leading-5 text-gray-700 ${
            active ? "bg-gray-100" : ""
          } focus:outline-none transition duration-150 ease-in-out font-medium`}
          {...props}
        >
          {children}
        </button>
      )}
    </Menu.Item>
  );
};

export default DropdownLink;
