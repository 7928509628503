import AppContext from "@/context/AppContext";
import { useContext } from "react";

import Image from "next/image";

import Text from "../Text/Text";
import LinkList from "../LinkList/LinkList";
import LinkedinIcon from "../Icons/LinkedinIcon";
import logofooter from "@/assets/images/tarjama-footer.svg";
import logofooterAr from "@/assets/images/tarjama-footer-ar.svg";
import {
  BLOG,
  BLOG_AR,
  YOUTUBE_LINK,
  TWITTER_LINK,
  CAREERS_LINK,
  FACEBOOK_LINK,
  ABOUT_US_LINK,
  LINKED_IN_LINK,
  INSTAGRAM_LINK,
  TERMS_COND_LINK,
  CAREERS_LINK_AR,
  CONTACT_US_LINK,
  ABOUT_US_LINK_AR,
  TARJAMA_HELP_LINK,
  CASE_STUDIES_LINK,
  TERMS_COND_LINK_AR,
  CONTACT_US_LINK_AR,
  OUR_TECHNOLOGY_LINK,
  PRIVACY_NOTICE_LINK,
  TARJAMA_HELP_LINK_AR,
  CASE_STUDIES_LINK_AR,
  OUR_TECHNOLOGY_LINK_AR,
  PRIVACY_NOTICE_LINK_AR,

} from "@/common/utils/constant";

import TwitterIcon from "../Icons/TwitterIcon";
import YoutubeIcon from "../Icons/YoutubeIcon";
import FacebookIcon from "../Icons/FacebookIcon";
import InstagramIcon from "../Icons/InstagramIcon";
import UserContext from "@/context/UserContext";

const Footer = () => {
  const context = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const setModalType = usercontext.setModalType;
  const setShowModal = usercontext.setShowModal;
  const t = context.t;
  const isArabic = context.locale === "ar";

  const linklist1 = [
    {
      link: isArabic ? ABOUT_US_LINK_AR : ABOUT_US_LINK,
      text: t.AboutUs,
      target: "_blank",
    },
    {
      link: isArabic ? OUR_TECHNOLOGY_LINK_AR : OUR_TECHNOLOGY_LINK,
      text: t.OurTechnology,
      target: "_blank",
    },
    {
      link: isArabic ? CONTACT_US_LINK_AR : CONTACT_US_LINK,
      text: t.ContactUs,
      target: "_blank",
    },
    {
      link: isArabic ? PRIVACY_NOTICE_LINK_AR : PRIVACY_NOTICE_LINK,
      text: t.PrivacyNotice,
      target: "_blank",
    },
    {
      link: isArabic ? TERMS_COND_LINK_AR : TERMS_COND_LINK,
      text: t.TermsConditions,
      target: "_blank",
    },
  ];
  const linklist2 = [
    {
      link: isArabic ? CAREERS_LINK_AR : CAREERS_LINK,
      text: t.WorkWithUs,
      target: "_blank",
    },
    {
      link: isArabic ? BLOG_AR : BLOG,
      text: t.Blog,
      target: "_blank",
    },
    {
      link: isArabic ? CASE_STUDIES_LINK_AR : CASE_STUDIES_LINK,
      text: t.CaseStudies,
      target: "_blank",
    },
    {
      link: isArabic ? TARJAMA_HELP_LINK_AR : TARJAMA_HELP_LINK,
      text: t.HelpCenter,
      target: "_blank",
    },
  ];
  const linklist3 = [
    {
      text: t.linkedin,
      link: LINKED_IN_LINK,
      icon: <LinkedinIcon />,
      target: "_blank",
    },
    {
      text: t.facebook,
      link: FACEBOOK_LINK,
      icon: <FacebookIcon />,
      target: "_blank",
    },
    {
      text: t.twitter,
      link: TWITTER_LINK,
      icon: <TwitterIcon />,
      target: "_blank",
    },
    {
      text: t.instagram,
      link: INSTAGRAM_LINK,
      icon: <InstagramIcon />,
      target: "_blank",
    },
    {
      text: t.youtube,
      link: YOUTUBE_LINK,
      icon: <YoutubeIcon />,
      target: "_blank",
    },
  ];
  const showTermsAndConditionModal = () => {
    setModalType("terms&conditions");
    setShowModal(true);
  };
  return (
    <div className="footerContainer">
      <div className="footer-content max-w-8xl m-auto p-5 lg:p-0 border-b border-solid border-grey800 lg:pb-10">
        <div
          className={`row ${isArabic ? "flex-row-reverse" : ""
            } row font-urwarabic text-right justify-between flex-wrap w-full`}
        >
          <div className={`col w-full lg:w-6/12 flex ${isArabic ? "justify-end" : "justify-start"}`}>
            <div className={`logoFooter w-6/12 lg:w-full mt-0 ${isArabic ? "text-right" : ""}`}>
              <Image src={isArabic ? logofooterAr : logofooter} width={250} height={70} />
              <Text
                as="p"
                className={`font-normal hidden lg:block text-grey200 ${isArabic
                  ? "text-right md:pl-[20px] lg:pl-[30px] xl:pl-[40px] "
                  : "xl:pr-[40px] mt-[40px]"
                  }`}
              >
                {t.companyAboutHeader}
                <br />
                {t.companyAbout}
              </Text>
            </div>
          </div>
          <div
            className={`flex flex-col font-urwarabic gap-6 text-right flex-wrap w-full lg:w-6/12`}
          >
            <div className={`hidden lg:flex text-xl font-bold  ${isArabic ? "flex-row-reverse" : ""}`}>
              <span>{t.sneakPeek}</span>
              <span className="ms-2 text-primaryDark">{t.lineBusiness}</span>
            </div>
            <div className={`flex justify-between ${isArabic ? "flex-row-reverse" : ""}`}>
              <div className="col">
                <div
                  className={`link-list${isArabic
                    ? "text-center sm:text-center md:text-right lg:text-right"
                    : "text-center sm:text-center md:text-left lg:text-left"
                    }`}
                >
                  <Text className="font-semibold text-normal lg:text-lg text-grey400 uppercase hidden lg:block">
                    {t.company}
                  </Text>
                  <ul className="mt-[24px] text-grey400">
                    {linklist1.map((item, index) => (
                      <LinkList
                        key={index}
                        item={item}
                        className={`${isArabic ? "text-right" : "text-left"}`}
                      />
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col">
                <div
                  className={`link-list text-center sm:text-center ${isArabic ? "md:text-right lg:text-right" : "md:text-left lg:text-left"
                    }`}
                >
                  <Text className="font-semibold text-normal lg:text-lg text-grey400 uppercase hidden lg:block">
                    {t.usefulLinks}
                  </Text>
                  <ul className="mt-[24px] text-md text-grey400">
                    {linklist2.map((item, index) => (
                      <LinkList
                        key={index}
                        item={item}
                        className={`${isArabic ? "text-right" : "text-left"}`}
                      />
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col hidden lg:block">
                <div
                  className={`text-center sm:text-center link-list${isArabic ? "md:text-right lg:text-right" : "md:text-left lg:text-left"
                    }`}
                >
                  <Text
                    className={`font-semibold text-normal lg:text-lg text-grey400 uppercase text-left`}
                  >
                    {t.socialMedia}
                  </Text>
                  <ul className={`mt-[24px] text-grey400 flex flex-col ${isArabic ? 'items-end' : ''}`}>
                    {linklist3.map((item, index) => (
                      <LinkList
                        key={index}
                        item={item}
                        isArabic={isArabic}
                        className="flex items-center"
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ul className="flex lg:hidden w-full items-center text-center justify-center pt-2">
        {linklist3.map((item, index) => (
          <li key={index} className={` ${isArabic ? "ms-2" : "me-2"}`}><a href={item.link}>{item.icon}</a></li>
        ))}
      </ul>
      <p className="text-sm lg:text-normal py-5 lg:pb-0 font-normal w-full text-center text-grey400">{t.copyright}</p>
    </div>
  );
};

export default Footer;
