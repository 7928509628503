import { useState, useEffect, useContext } from "react";

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import AppContext from "@/context/AppContext";
import Input from "@/common/components/elements/Input/Input";

export default function EditTeamMember({ onCloseDialog, selectedMemberData, updateMember }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    onCloseDialog();
  };
  function onUpdateMember() {
    setOpen(false);
    updateMember({
      name: name,
      email: email,
      num_files_per_user: +files,
      id: selectedMemberData.id,
      team_id: selectedMemberData.team_id,
      user_id: selectedMemberData.user_id,
      num_words_per_user_text_translation: +textWords,
      num_words_per_user_document: +documentsWords,
      num_char_per_user_text_translation: +documentsChars,
      num_pages_per_user_website_extraction: +webExtractionPages,
    });
  };

  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const isArabic = locale === "ar";
  const [errorMsg, setErrorMsg] = useState('');
  const [name, setName] = useState(selectedMemberData.name);
  const [email, setEmail] = useState(selectedMemberData.email);
  const [files, setFiles] = useState(selectedMemberData.num_files_per_user);
  const [textWords, setTextWords] = useState(selectedMemberData.num_words_per_user_text_translation);
  const [documentsWords, setDocumentsWords] = useState(selectedMemberData.num_words_per_user_document);
  const [documentsChars, setDocumentsChars] = useState(selectedMemberData.num_char_per_user_text_translation);
  const [webExtractionPages, setWebExtractionPages] = useState(selectedMemberData.num_pages_per_user_website_extraction);

  const checkEmailVAlidity = (name, eventTarget) => {
    setEmail(eventTarget.value);
    if (!/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[a-z]{2,4}$/.test(eventTarget.value)) {
      const customMessage = isArabic ? "الرجاء ادخال بريد الكتروني صحيح" : "Please enter a valid email"
      eventTarget.setCustomValidity(customMessage);
      setErrorMsg(customMessage);
    } else {
      eventTarget.setCustomValidity('')
      setErrorMsg('');
    }

  }

  const maxWidth = 'sm';
  const fullWidth = true;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title" className="text-lg font-semibold text-center">
        {t.EditTeamMember}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="flex flex-col gap-5">
          <div className="w-full gap-3 flex">
            <div className="w-1/2">
              <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                {t.Name} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
              </label>
              <Input
                placeholder={t.Name}
                type="text"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                  }`}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className="w-1/2">
              <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                {t.Email} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
              </label>
              <Input
                placeholder={t.Email}
                disabled
                type="email"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                  }`}
                value={email}
                onChange={(event) => checkEmailVAlidity("email", event.target)}
              />
              <span className="text-red text-sm">{errorMsg}</span>
            </div>
          </div>
          <p className="font-bold">{t.Limitations}</p>
          <div className="w-full gap-5 flex">
            <div className="w-1/2">
              <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                {t.Files} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
              </label>
              <Input
                placeholder={t.Files}
                type="text"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                  }`}
                value={files}
                onChange={(event) => setFiles(event.target.value)}
              />
            </div>
            <div className="w-1/2">
              <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                {t.TextWords} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
              </label>
              <Input
                placeholder={t.TextWords}
                type="text"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                  }`}
                value={textWords}
                onChange={(event) => setTextWords(event.target.value)}
              />
            </div>
          </div>
          <div className="w-full gap-5 flex">
            <div className="w-1/2">
              <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                {t.DocumentsWords} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
              </label>
              <Input
                placeholder={t.DocumentsWords}
                type="text"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                  }`}
                value={documentsWords}
                onChange={(event) => setDocumentsWords(event.target.value)}
              />
            </div>
            <div className="w-1/2">
              <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                {t.WebExtractionPages} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
              </label>
              <Input
                placeholder={t.WebExtractionPages}
                type="text"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                  }`}
                value={webExtractionPages}
                onChange={(event) => setWebExtractionPages(event.target.value)}
              />
            </div>
          </div>
          <div className="w-full gap-5 flex">
            <div className="w-1/2">
              <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                {t.DocumentsChars} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
              </label>
              <Input
                placeholder={t.DocumentsChars}
                type="text"
                className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                  }`}
                value={documentsChars}
                onChange={(event) => setDocumentsChars(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className='flex w-full items-center justify-center'>
          <Button variant="outlined" className="btn-green text-normal mt-20 py-2"
            onClick={() => onUpdateMember()}>{t.SaveEdits}</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

