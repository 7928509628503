import Link from "next/link";
import React, { useContext } from "react";
import AppContext from "../../../../context/AppContext";

const TopNotificationBar = ({
  notification,
  backgroundColor = false,
  state = false,
}) => {
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;

  return (
    <div
      className={`topNotification ${
        backgroundColor ? "bg-" + backgroundColor : "bg-black"
      } text-white text-[14px] p-[8px] text-center font-light ${
        locale == "ar" ? "font-urwarabic" : ""
      }`}
    >
      <bdi className="">
        {notification.text}{" "}
        {notification.link ? (
          <Link href={notification.link}>
            <a className="font-semibold border-b" target="_blank">
              {notification.btnText}
            </a>
          </Link>
        ) : (
          
          <button
            className={`font-normal px-3 py-1 text-${backgroundColor}`}
            onClick={() => {
              state(true);
            }}
          >
            {notification.btnText}
          </button>
        )}
        {notification.secondaryLinkText && (
          <>
            <span className="px-2"> {t.Or}</span>
            <Link href={notification.secondaryLink}>
              <a className="font-semibold border-b">
                {notification.secondaryLinkText}
              </a>
            </Link>
          </>
        )}
      </bdi>
    </div>
  );
};

export default TopNotificationBar;
