/* eslint-disable react/jsx-key */
import Link from "next/link";

const LinkList = ({ item, className = "", isArabic }) => {
  return (
    <li
      className={`text-[16px] mb-3.5 font-medium ${className}`}
    >
      <Link href={item.link}>
          <a target={item.target} className={`${item.icon ? "flex items-center" : ""} ${isArabic ? "flex-row-reverse" : ""}`}>
            <span className={` ${item.icon ? isArabic ? "ms-2" : "me-2" : ""}`}>{item.icon}</span>
            {item.text}</a>
      </Link>
    </li>
  );
};

export default LinkList;
