import styles from "./header.module.scss";
import MainNavigation from "../Layout/MainNavigation";
import React from "react";

const Header = React.forwardRef(({ user }, ref) => {
  return (
    <div className={styles.headerContainer}>
      <MainNavigation user={user} ref={ref} />
    </div>
  );
});

export default Header;
