import { useRouter } from "next/router";
import { useCookies } from 'react-cookie';
import { useAuth } from "../common/hooks/auth";
import styles from "../../styles/Home.module.scss";
import CircularProgress from '@mui/material/CircularProgress';
import { useContext, useEffect, useRef, useState } from "react";

// Module
import AppContext from "../context/AppContext";
import UserContext from "../context/UserContext";
import Default from "@/pages/default/index.js";
import EnterpriseHome from "@/pages/enterprise/home.js";
import EnterpriseHistory from "@/pages/enterprise/history.js";
import EnterpriseTeam from "@/pages/enterprise/team.js";
import EnterpriseAccount from "@/pages/enterprise/accountInfo.js";

import {
  ADMIN_ROLE_ID,
  SIGNUP_ADD_BILLING_STEP,
  SIGNUP_CHOOSE_PLAN_STEP,
} from "@/common/utils/constant";

export default function Home() {
  const { user } = useAuth({
    middleware: "guest",
  });
  const router = useRouter();
  const usercontext = useContext(UserContext);
  const setShowModal = usercontext.setShowModal;
  const setModalType = usercontext.setModalType;
  const setStep = usercontext.setStep;
  const isTeamUser = usercontext.user?.team_id;
  const homeUrl = '/enterprise/home';
  const historyUrl = '/enterprise/history';
  const teamUrl = '/enterprise/team';
  const accountUrl = '/enterprise/accountInfo';
  const [cookies, setCookie] = useCookies(['cookie-name']);
  const [allowCookie, setAllowCookie] = useState(false);
  const [loading, setLoading] = useState(true);

  const pageRoute = cookies.route || 'home';
  //const [pageRoute, setPageRoute] = useState(cookies.route || 'home');
  const pathname = pageRoute === 'home' ? homeUrl : pageRoute === 'history' ? historyUrl : pageRoute === 'team' ? teamUrl : accountUrl;
  //const pageRoute = cookies.route || 'home';
  useEffect(() => {
    if (cookies.allowCookie) setAllowCookie(true);
    if (user) {
      if (user?.role_id !== ADMIN_ROLE_ID) {
        if (!user?.subscription) {
          setShowModal(true);
          setModalType("signup");
          setStep(SIGNUP_CHOOSE_PLAN_STEP);
        } else {
          if (user?.role_id !== ADMIN_ROLE_ID) {
            if (!user?.subscription) {
              setShowModal(true);
              setModalType("signup");
              setStep(SIGNUP_CHOOSE_PLAN_STEP);
            } else {
              if (!user?.billing && user?.plan_type !== "free" && user?.plan_type !== "custom" && !cookies.skip_card) {
                setStep(SIGNUP_ADD_BILLING_STEP);
                setShowModal(true);
                setModalType("signup");
              }
            }
          }
        }
      }
      if (isTeamUser) {
        router.push({
          pathname: pathname,
        }, undefined, { shallow: true })
      }
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  }, [user]);
  return (
    <div className={`${styles.container}`}>
      {loading ?
        <div className='w-full flex items-center justify-center h-screen'><CircularProgress /> </div> :
        !isTeamUser ? <Default /> :
          pageRoute === 'home' ? <EnterpriseHome /> : pageRoute === 'history' ? <EnterpriseHistory /> : pageRoute === 'team' ? <EnterpriseTeam /> : <EnterpriseAccount />
      }
    </div>
  );
}
