import React, { useContext } from "react";

import Image from "next/image";
import AppContext from "@/context/AppContext.js";
import Trans from "../../../src/assets/images/trans/transBy.png";

const TransBy = () => {
  const context = useContext(AppContext);
  const t = context.t;
  const locale = context.locale;
  const isArabic = locale === "ar";
  return (
    <div className={`flex flex-row border rounded p-5 lg:py-2 flex-wrap items-center justify-start lg:justify-center items-center bg-white ${isArabic ? 'flex-row-reverse' : ''}`}>
      <div className={`flex items-center mb-3 lg:mb-0 ${isArabic ? 'flex-row-reverse' : ''}`}>
        <Image src={Trans} width={20} height={20} />
        <span className="mx-3">{t.BuiltForBusiness}</span>
      </div>
      <div className={`flex items-center mb-3 lg:mb-0 ${isArabic ? 'flex-row-reverse' : ''}`}>
        <Image src={Trans} width={20} height={20} />
        <span className="mx-3">{t.BestIn}</span>
      </div>
      <div className={`flex items-center mb-3 lg:mb-0 ${isArabic ? 'flex-row-reverse' : ''}`}>
        <Image src={Trans} width={20} height={20} />
        <span className="mx-3">{t.MaximizedSecurity}</span>
      </div>
      <div className={`flex items-center mb-3 lg:mb-0 ${isArabic ? 'flex-row-reverse' : ''}`}>
        <Image src={Trans} width={20} height={20} />
        <span className="mx-3">{t.AdvancedDocument}</span>
      </div>
      <div className={`flex items-center ${isArabic ? 'flex-row-reverse' : ''}`}>
        <Image src={Trans} width={20} height={20} />
        <span className="mx-3">{t.BuiltByTranslators}</span>
      </div>
    </div>
  );
};

export default TransBy;
