import React, { useContext, useState } from "react";
import { useRouter } from "next/router";

import GlobeIcon from "../Icons/GlobeIcon";
import AppContext from "../../../../context/AppContext";

function LangSwitch() {
  const router = useRouter();
  const language = router.locale ?? "en";
  const isEnglish = router.locale === "en";
  const appcontext = useContext(AppContext);
  const setSelectedLocale = appcontext.setSelectedLocale;
  const [isHovering, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const handleOnclick = () => {
    const newLang = isEnglish ? "ar" : "en";
    setSelectedLocale(newLang);
    let urlparts = location.pathname;
    if (urlparts === '/en' || urlparts === '/ar') {
      urlparts = '';
    }
    const string = urlparts.replace(/(ar\/|en\/)/g, '');
    router.push({
      pathname: newLang === 'en' ? string : `/ar${string}`,
    }, undefined, { locale: false });
  };
  return (
    <button
      className={`inline-flex items-center text-sm sm:text-base md:text-sm lg:text-md text-black font-sans font-medium color-black leading-5 
                  focus:outline-none transition duration-150 ${isEnglish ? "mr-5" : ""}`}
      onClick={handleOnclick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={`${isEnglish ? "pr-[5px]" : "pl-[5px]"}`}>
        {isHovering ? (
          <GlobeIcon color={"#02847B"} />
        ) : (
          <GlobeIcon color={"#101820"} />
        )}
      </span>
      {isEnglish ? "AR" : "EN"}
    </button>
  );
}

export default function UseSwitchesCustom() {
  return <LangSwitch />;
}
