import { useContext, useState, useEffect } from "react";
import Link from "next/link";
import Box from '@mui/material/Box';
import { useCookies } from 'react-cookie';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import { styled, alpha } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import axios from "@/common/libs/axios";
import AppContext from "@/context/AppContext";
import UserContext from "@/context/UserContext";
import ApplicationLogo from "../ApplicationLogo/ApplicationLogo";
import LangSwitch from "@/common/components/elements/LangSwitch/LangSwitch";
import { isMobile } from "react-device-detect";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function EnterpriseHeader({ onMobileMenuOpen }) {
  const appcontext = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const [cookies, setCookie] = useCookies(['cookie-name']);

  const user = usercontext.user;
  const userName = user?.name;
  const t = appcontext.t;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    onMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    if (!companyLogo) {
      axios
        .get("/api/user/team-info")
        .then((res) => {
          setCompanyLogo(res.data.company_logo);
         // setCookie('companyLogo', res.data.company_logo);
        })
    }
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" className="bg-white w-full shadow-none border-b">
        <Toolbar className={`w-full justify-between`}>
          {isMobile &&
            <>
              <div className="flex items-center md:hidden">
                <Link href="/">
                  <a>
                    <ApplicationLogo className="block h-5 w-auto fill-current text-gray-600" />
                  </a>
                </Link>
              </div>
              <div className="flex justify-between">
                <LangSwitch className="text-sm" />
                <IconButton
                  size="large"
                  edge="start"
                  color="black"
                  aria-label="menu"
                >
                  <MenuIcon onClick={() => toggleMobileMenu()} />
                </IconButton>
              </div>
            </>
          }
          <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'space-between', flexGrow: 1 }}>
            <div className="flex">
              {companyLogo &&
                <div style={{
                  width: companyLogo ? '70px' : '0px',
                  height: '50px',
                  backgroundImage: `url(${companyLogo})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain'
                }} className="mr-5" ></div>
              }
              <div className="rounded-full bg-grey600 p-2 flex text-md items-center text-black font-medium">
                <StarOutlineRoundedIcon /> {t.Enterprise}
              </div>
            </div>
            <div className="flex items-center">
              <LangSwitch className="md:text-sm lg:text-md" />
              <div className="h-full border bg-grey mr-16 ml-16"></div>
              <PersonOutlineOutlinedIcon sx={{ color: "black" }} />
              <p className="text-black font-semibold text-md">{t.Welcome}, {userName}</p>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
