import Link from "next/link";
import { useContext } from "react";
import AppContext from "../../../../context/AppContext";

const NavLink = ({
  active = false,
  bordered = false,
  children,
  onClick,
  ...props
}) => {
  const appcontext = useContext(AppContext);
  const locale = appcontext.locale;
  return (
    <Link {...props}>
      <a
        onClick={onClick}
        target={props.target}
        className={`inline-flex items-center px-1 pt-1 sm:text-[12px] md:text-sm lg:text-md text-black font-sans color-black leading-5 focus:outline-none transition duration-150 ease-in-out ${
          active
            ? "focus:border-red"
            : `hover:text-darkgreen hover:border-darkgreen focus:text-green-700 focus:border-green-300 ${
                bordered
                  ? `border-[1px] pb-[8px] font-medium pt-[9px] px-[22px] border-black rounded-full`
                  : `border-transparent border-b font-medium `
              }`
        } ${locale == "ar" ? "mr-6 font-urwarabic" : "ml-6"}`}
      >
        {children}
      </a>
    </Link>
  );
};

export default NavLink;
