import { useState, useEffect, useContext } from "react";

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import axios from "@/common/libs/axios";
import AppContext from "@/context/AppContext";
import Input from "@/common/components/elements/Input/Input";

export default function InviteTeamMember({ onCloseDialog, onInviteMember }) {
  const [open, setOpen] = useState(true);
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const locale = appcontext.locale;
  const isArabic = locale === "ar";
  const [email, setEmail] = useState('');
  const [inviteMessage, setInviteMessage] = useState('');
  const [errorMsg, setErrorMsg] = useState(null)

  const checkEmailVAlidity = (name, eventTarget) => {
    setEmail(eventTarget.value);
    if (!/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[a-z]{2,4}$/.test(eventTarget.value)) {
      const customMessage = isArabic ? "الرجاء ادخال بريد الكتروني صحيح" : "Please enter a valid email"
      eventTarget.setCustomValidity(customMessage);
      setErrorMsg(customMessage);
    } else {
      eventTarget.setCustomValidity('')
      setErrorMsg('');
    }

  }

  const handleClose = () => {
    setOpen(false);
    onCloseDialog();
  };

  const inviteMember = () => {
    setOpen(false);
    onInviteMember({ email: email });
  }
  const maxWidth = 'sm';
  const fullWidth = true;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-lg font-semibold text-center">
          {t.InviteTeamMember}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            <div>
              <div className="mb-4">
                <label className={`block text-black font-medium mb-2 ${isArabic ? "text-right rtl-input" : ""}`}>
                  {t.Email} <span className={`text-red ${locale == "ar" ? "text-right" : ""}`}>*</span>
                </label>
                <Input
                  placeholder={t.Email}
                  type="email"
                  className={`p-2 px-4 h-12 appearance-none outline-none bg-transparent text-black font-semibold  placeholder:text-grey400 focus:ring-transparent outline-offset-0 bg-white p-1 flex border border-grey200 focus:border-[1.5px] focus:border-black rounded  w-full ${isArabic ? "text-right rtl-input" : ""
                    }`}
                  value={email}
                  onChange={(event) => checkEmailVAlidity("email", event.target)}
                />
                <span className="text-red text-sm">{errorMsg}</span>
              </div>
            </div>
          </DialogContentText>
          <div className='flex w-full items-center justify-center'>
            <Button variant="outlined" className="btn-green mr-5 text-normal" disabled={errorMsg !== ''} onClick={inviteMember}>{t.SendInvitation}</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

