import { useCookies } from 'react-cookie';
import { isMobile } from "react-device-detect";
import { useAuth } from "@/common/hooks/auth";
import { useContext, useEffect, useRef, useState } from "react";
import Layout from "@/common/components/elements/Layout/Layout.js";
import HeroBackground from "@/common/components/elements/Background/HeroBackground.js";
// Module
import Image from "next/image";
import Slider from "@/modules/Slider/Slider";
import Trans from "@/modules/TransBy/TransBy";
import AppContext from "@/context/AppContext";
import Pricing from "@/modules/Pricing/Pricing";
import UserContext from "@/context/UserContext";
import TranslationContext from "@/context/TranslationContext";
import Button from "@/common/components/elements/Button/Button";
import ModalPopup from "@/common/components/elements/ModalPopup/ModalPopup";
import TranslationWidget from "@/modules/TranslationWidget/TranslationWidget";

import cookiesImage from "@/assets/images/cookies.png";

import {
  PRIVACY_NOTICE_LINK,
  PRIVACY_NOTICE_LINK_AR,
} from "@/common/utils/constant";

export default function Home() {
  const { user } = useAuth({
    middleware: "guest",
  });
  const usercontext = useContext(UserContext);
  const appcontext = useContext(AppContext);
  const cardRef = useRef();
  const modalType = usercontext.modalType;
  const setShowModal = usercontext.setShowModal;
  const t = appcontext.t;
  const isArabic = appcontext.locale === "ar";
  const showModal = usercontext.showModal;
  const [limitExceed, setLimitExceed] = useState(false);
  const [docLimitExceed, setDocLimitExceed] = useState(false);
  const [docLimitExceedMsg, setDocLimitExceedMsg] = useState('');

  const [limitExceedType, setLimitExceedType] = useState(0);
  const [limitExceedMsg, setLimitExceedMsg] = useState('');

  const [allTranslate, setAllTranslate] = useState(false);

  const [errorTranslate, setErrorTranslate] = useState(false);

  const [allTranslatedDocuments, setAllTranslatedDocuments] = useState([]);

  const [docs, setDocs] = useState([]);
  const [displayDocs, setDisplayDocs] = useState([]);

  const [model, setModel] = useState("en-ar");
  const [translations, setTranslations] = useState([]);

  const [lLang, setLLang] = useState({ lang: t.English, model: "en" });
  const [rLang, setRLang] = useState({ lang: t.Arabic, model: "ar" });

  const [cookies, setCookie] = useCookies(['cookie-name']);
  const [allowCookie, setAllowCookie] = useState(false);

  const PRIVACY_NOTICE_URL = isArabic ? PRIVACY_NOTICE_LINK_AR : PRIVACY_NOTICE_LINK;

  const acceptCookies = () => {
    setAllowCookie(true)
    setCookie('allowCookie', true);
  };
  useEffect(() => {
    if (cookies.allowCookie) setAllowCookie(true);
  });
  return (
    <TranslationContext.Provider
      value={{
        docs: docs,
        setDocs: setDocs,
        displayDocs: displayDocs,
        setDisplayDocs: setDisplayDocs,
        allTranslate: allTranslate,
        setAllTranslate: setAllTranslate,
        errorTranslate: errorTranslate,
        setErrorTranslate: setErrorTranslate,
        model: model,
        setModel: setModel,
        setLLang: setLLang,
        setRLang: setRLang,
        rLang: rLang,
        lLang: lLang,
        translations: translations,
        setTranslations: setTranslations,
        setLimitExceed: setLimitExceed,
        limitExceed: limitExceed,
        limitExceedType: limitExceedType,
        setLimitExceedType: setLimitExceedType,
        setLimitExceedMsg: setLimitExceedMsg,
        limitExceedMsg: limitExceedMsg,
        setDocLimitExceed: setDocLimitExceed,
        setDocLimitExceedMsg: setDocLimitExceedMsg,
        docLimitExceedMsg: docLimitExceedMsg,
        docLimitExceed: docLimitExceed,
        setAllTranslatedDocuments: setAllTranslatedDocuments,
        allTranslatedDocuments: allTranslatedDocuments,
      }}
    >
      <HeroBackground />
      <Layout
        ref={cardRef}
        usr={user}
        className="mb-[50px] md:mb-[140px] lg:mb-[160px] xl:mb-[175px]"
        title="Tarjama AMT - The best Arabic translation tool"
        description="Best online translator - translate your text and document with Tarjama translator"
      >
        <TranslationWidget />
        <div className="px-4 sm:px-6 md:px-6 lg:px-8 xl:px-main py-10">
          <Trans />
        </div>
        {/*
            <span className="block md:hidden pb-5 w-full text-center">{t.TrustedPartners}</span>
            <Slider images={images} />
          */}
        <Pricing ref={cardRef} />
      </Layout>
      <ModalPopup
        showModal={showModal}
        setShowModal={setShowModal}
        type={modalType}
      />
      {!allowCookie &&
        <div style={{
          width: "97%",
          margin: "auto"
        }} className={`shadow-xl bg-lightRed fixed flex w-full bottom-0 p-5 !mb-[20px] rounded right-0 left-0
            ${isMobile ? 'flex-col' : ''} 
            ${isArabic ? 'flex-row-reverse' : ''}`}>
          {!isMobile && <Image src={cookiesImage} width={80} height={70} />}
          <div className={`flex flex-col w-full ${isArabic ? 'text-right mr-5' : 'ml-5'}`}>
            <p className="mb-3 font-semibold text-black text-md">{t.websiteCookies}</p>
            <p className="text-grey text-sm">
              {t.websiteCookiesBody}
              <a target="_blank" href={PRIVACY_NOTICE_URL} className="underline text-green text-[10px] cursor-pointer text-base font-semibold" >
                {t.PrivacyNotice}
              </a>
            </p>
          </div>
          <Button
            type="button"
            className={`bg-green text-white mt-[16px] w-fit font-semibold btn-outline-green btn-block hover:bg-darkgreen rounded-full hover:rounded-full`}
            onClick={() => acceptCookies()}
          >
            {t.Accept}
          </Button>
        </div>
      }
    </TranslationContext.Provider>
  );
}
