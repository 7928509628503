const Heading = ({
  headingClasses,
  heading,
  subheading = false,
  subheadingClasses,
  children,
}) => {
  return (
    <div className="w-full text-center flex flex-col gap-1">
      <div>
        <h2 className={`${headingClasses} text-ml sm:text-md md:text-lg lg:text-lg xl:text-xl text-black font-semibold md:leading-[46px] xl:leading-[56px]`}>
          {heading}
          {children}
        </h2>
      </div>
      <div>
        <p
          className={`${subheadingClasses} text-sm md:text-sm lg:text-base xl:text-base font-medium text-grey mt-16px md:mt-[14px] lg:mt-[16px] xl:mt-[18px]`}
        >
          {subheading}
        </p>
      </div>
    </div>
  );
};

export default Heading;
