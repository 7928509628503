import React, { useContext } from "react";
import { isMobile } from "react-device-detect";

import AppContext from "@/context/AppContext";
import CardPricing from "@/common/components/elements/Cards/CardPricing";
import Heading from "@/common/components/elements/Heading/Heading";
import BlurBackground from "@/common/components/elements/Background/BlurBackground.js";

// eslint-disable-next-line no-empty-pattern
const Pricing = React.forwardRef(({ }, ref) => {
  const context = useContext(AppContext);
  const t = context.t;
  const plans = context.plans;
  const locale = context.locale;
  const plan_details = locale == "en" ? context.plansEN : context.plansAR;
  const extraClasses = {
    mainHeadingClasses: "lg:text-md xl:text-32 font-bold",
    pricingClasses:
      "text-lg md:text-normal lg:text-lg mt-[18px] md:mt-[14px] lg:mt-[18px] xl:mt-[22px]",
    pricingSupClasses: "text-sm md:text-sm lg:text-md xl:text-md",
    pricingSubClasses: "text-sm md:text-md",
    buttonClasses: "",
    buttonTextClasses: "",
    trialClasses: "",
    badgeClasses: ` ${locale == "ar"
      ? "mr-1 text-[8px] md:text-[12px] lg:text-[14px] min-w-[90px] text-center"
      : "text-[14px] ml-1 "
      }`,
    featuresClasses: "mb-[16px]",
    featureIconWidth: "16",
    featureIconHeight: "16",
    badgeIconH: "16",
    badgeIconW: "16",
    checkboxWidth: 16,
  };
  return (
    <div className="pt-[30px] md:pt-[40px] lg:pt-[60px] relative">
      <BlurBackground />
      <div
        className="pb-[10px] px-10 md:px-0 md:pb-[36px] lg:pb-[45px] xl:pb-[56px]"
        ref={ref}
      >
        <Heading
          headingClasses=""
          subheadingClasses=""
          heading={t.PricingHeading}
          subheading={t.PricingSubHeading}
        />
      </div>
      <div
        className={`w-full mt-[20px] flex md:justify-center gap-4 pt-5 md:pt-0 overflow-scroll md:overflow-visible 
            ${isMobile ? 'items-center' : ''}
            ${locale == "ar" ? "flex-row-reverse" : ""} `}
      >
        {plans?.map((plan, key) => (
          <CardPricing
            ref={ref}
            key={key}
            pricePlan={plan}
            planDetail={plan_details[key]}
            className={`w-full md:max-w-[320px] lg:max-w-[400px] xl:max-w-[386px] 
                      ${plan.is_featured == "1" ? "" : "my-[40px]"}`}
            extraClasses={extraClasses}
          />
        ))}
      </div>
    </div>
  );
});

export default Pricing;
