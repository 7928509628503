import React, { useContext, useState } from "react";

import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";

import Button from "../Button/Button";
import NavLink from "../NavLink/NavLink";
import Dropdown from "../Dropdown/Dropdown";
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import UserContext from "@/context/UserContext";
import TimerIcon from "@/assets/images/timer.png";
import LangSwitch from "../LangSwitch/LangSwitch";
import userIcon from "@/assets/images/user-bk.png";
import rankingIcon from "@/assets/images/upgrade-green.png";
import ApplicationLogo from "../ApplicationLogo/ApplicationLogo";
import DropdownLink, { DropdownButton } from "../DropdownLink/DropdownLink";
import ResponsiveNavLink, { ResponsiveNavButton, } from "../ResponsiveNavLink/ResponsiveNavLink";

import {
  ABOUT_AMT,
  ABOUT_AMT_AR,
  ABOUT_US_LINK,
  ABOUT_US_LINK_AR,
  CONTACT_US_LINK,
  CONTACT_US_LINK_AR,
  USER_BASIC_INFO_URL,
  USER_TRANSLATION_HISTORY_URL,
} from "@/common/utils/constant";
import UserIcon from "../Icons/UserIcon";
import Model from './Model'; // Update the path based on your project structure


// eslint-disable-next-line no-empty-pattern
const MainNavigation = React.forwardRef(({ }, ref) => {
  const [isModelOpen, setIsModelOpen] = useState(false);

  const openModel = () => {
    setIsModelOpen(true);
  };

  const closeModel = () => {
    setIsModelOpen(false);
  };

  const router = useRouter();
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const [isHovering, setIsHovered] = useState(false);
  const onMouseEnter = () => setIsHovered(true);
  const onMouseLeave = () => setIsHovered(false);
  const context = useContext(AppContext);
  const usercontext = useContext(UserContext);
  const setModalType = usercontext.setModalType;
  const setShowModal = usercontext.setShowModal;
  const user = usercontext.user;
  const userPlan = usercontext.user?.plan;
  const remainingDays = user?.trial_remaining_days;
  const shouldShowUpgradeButton = userPlan === 1 || userPlan === 2;
  const t = context.t;
  const locale = context.locale;
  const isArabic = locale === "ar";


  function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY,
    };
  }

  const showSignupModal = () => {
    setModalType("signin");
    setShowModal(true);
  };
  return (

    <nav>
      {/* Primary Navigation Menu */}
      <div className="pt-2 max-w-7xl mx-auto px-4 sm:px-6 lg:px-[32px] lg:py-[12px] bg-white border-b">
        <div
          className={`flex justify-between h-fit items-center ${isArabic ? "flex-row-reverse font-urwarabic" : ""}`}
        >
          <div className="flex ">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <Link href="/">
                <a>
                  <ApplicationLogo className="block h-10 w-auto fill-current text-gray-600" />
                </a>
              </Link>
            </div>
          </div>

          {/* Settings Dropdown */}
          <div className="">
            {/* Navigation Links */}
            <div
              className={`hidden sm:flex nav-bar gap-5 ${isArabic ? "flex-row-reverse" : ""}`}
            >
              <NavLink
                href={isArabic ? ABOUT_US_LINK_AR : ABOUT_US_LINK}
                target="_blank"
              >
                {t.About}
              </NavLink>
              <NavLink
                href={isArabic ? CONTACT_US_LINK_AR : CONTACT_US_LINK}
                active={router.pathname === "/order_business_translation"}
                target="_blank"
              >
                {t.OrderBusinessTranslation}
              </NavLink>
              <NavLink
                href={isArabic ? CONTACT_US_LINK_AR : CONTACT_US_LINK}
                active={router.pathname === "/contact_support"}
                target="_blank"
              >
                {t.ContactUs}
              </NavLink>
              <NavLink
                href={"#"}
                onClick={() => {
                  router.pathname === "/"
                    ? setTimeout(
                      () =>
                        ref.current.scrollIntoView({
                          behavior: "smooth",
                        }),
                      200
                    )
                    : (window.location.href = "/" + locale);
                }}
                active={router.pathname === "/contact_support"}
              >
                {t.pricingAndPlan}
              </NavLink>
            </div>
          </div>
          <div>
            <div
              className={`hidden sm:flex ${isArabic ? "flex-row-reverse" : ""}`}
            >
              {shouldShowUpgradeButton && (
                // Render additional content here when shouldShowUpgradeButton is true
                <div
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  className="mr-4 flex"
                >
                  <Button
                    onClick={openModel}
                    className={`border-[1px] ${isArabic ? "flex-row-reverse" : ""
                      } bg-gradient-to-r from-teal-500  to-rose-400  rounded-full p-0.5  text-green hover:rounded-full hover:bg-black hover:text-green`}>
                    <span className="flex w-full bg-white text-green rounded-full p-1 items-center">
                      <span className="vuesax-broken flex items-center"><Image src={rankingIcon} width={20} height={20} />
                      </span>
                      {t.Upgrade}
                    </span>
                  </Button>
                </div>
              )}
              {user !== undefined && (
                <div className="hidden sm:flex sm:items-center mr-4">
                  <Dropdown
                    align="right"
                    width="w-40"
                    trigger={
                      <button>
                        <span className='flex w-full justify-center border border-black rounded-full'>
                          <UserIcon color={"#101820"} width={22} height={22} />
                        </span>
                      </button>

                    }
                  >
                    {/* Authentication */}
                    {user.role_id === process.env.ADMIN_ID && (
                      <>
                        <DropdownLink href="/admin/dashboard">
                          {t.Dashboard}
                        </DropdownLink>
                        <DropdownLink href="/admin/settings">
                          {t.Settings}
                        </DropdownLink>
                      </>
                    )}
                    {user.role_id !== process.env.ADMIN_ID && (
                      <>
                        <DropdownLink href={USER_TRANSLATION_HISTORY_URL}>
                          {t.translationHistory}
                        </DropdownLink>
                        <DropdownLink href={USER_BASIC_INFO_URL}>
                          {t.myAccount}
                        </DropdownLink>
                      </>
                    )}
                    <DropdownButton onClick={logout}>{t.Logout}</DropdownButton>
                  </Dropdown>
                  <p class="m-2 truncate max-w-[100px]">{user.name}</p>
                </div>
              )}
              {user === undefined && (
                <div
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  className="mr-4 flex gap-3"
                >
                  <Button onClick={() => showSignupModal()}
                    className={`border-[1px] btn bg-gradient-to-r from-teal-500  to-rose-400  rounded-full p-0.5 w-28 h-11 text-green hover:rounded-full hover:bg-black hover:text-green btn  ${isArabic ? "flex-row-reverse" : ""}`}>
                    <span className={`w-full bg-white inline text-green rounded-full p-2`}>
                      {t.tryFree}
                    </span>
                  </Button>
                  <button onClick={() => showSignupModal()}>
                    <span className='flex justify-center border border-black rounded-full'>
                      <UserIcon color={"#101820"} width={22} height={22} />
                    </span>
                  </button>
                </div>
              )}
              <LangSwitch className="md:text-sm lg:text-md" />
            </div>

            {/* Hamburger */}
            <div className={`-mr-2 flex items-center sm:hidden ${isArabic ? 'flex-row-reverse' : ''}`}>
              <LangSwitch className="md:text-sm lg:text-md" />
              <button
                onClick={() => setOpen((open) => !open)}
                className={`inline-flex ${isArabic ? "flex-row-reverse" : ""
                  } items-center justify-center p-2 rounded-lg text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out`}
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  {open ? (
                    <path
                      className="inline-flex"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  ) : (
                    <path
                      className="inline-flex"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  )}
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Responsive Navigation Menu */}
      {open && (
        <div className="block sm:hidden bg-white">
          <div className="pt-2 pb-3 space-y-1">
            <ResponsiveNavLink
              href={isArabic ? ABOUT_US_LINK_AR : ABOUT_US_LINK}
              active={router.pathname === "/about"}
              target="_blank"
            >
              {t.About}
            </ResponsiveNavLink>
            <ResponsiveNavLink
              href={isArabic ? CONTACT_US_LINK_AR : CONTACT_US_LINK}
              target="_blank"
              active={router.pathname === "/order_business_translation"}
            >
              {t.OrderBusinessTranslation}
            </ResponsiveNavLink>
            <ResponsiveNavLink
              href={isArabic ? CONTACT_US_LINK_AR : CONTACT_US_LINK}
              target="_blank"
              active={router.pathname === "/contact_support"}
            >
              {t.ContactUs}
            </ResponsiveNavLink>
            <ResponsiveNavLink
              href={isArabic ? CONTACT_US_LINK_AR : CONTACT_US_LINK}
              target="_blank"
            >
              Plans & Pricing
            </ResponsiveNavLink>
          </div>

          {/* Responsive Settings Options */}
          <div className="pb-1 border-t border-gray-200">
            <div className="flex items-center justify-between px-4">
              {user && (
                <div className="flex justify-between">
                  <a href={USER_TRANSLATION_HISTORY_URL}>
                    <div className="flex-shrink-0 mr-2">
                      <Image src={userIcon} width={22} height={22} />
                    </div>
                    <div className="font-medium text-base text-gray-800">
                      {user?.name}
                    </div>
                  </a>
                </div>
              )}
              {user === undefined && (
                <ResponsiveNavButton onClick={() => showSignupModal()}>
                  {t.Login}
                </ResponsiveNavButton>
              )}
              {user && (
                <ResponsiveNavButton onClick={logout}>
                  {t.Logout}
                </ResponsiveNavButton>
              )}
            </div>
          </div>
        </div>
      )}
      <div>
        {/* <button onClick={openModel}>Open Modal</button> */}

        <Model handleClose={closeModel} show={isModelOpen}>
          {/* Content inside the modal */}
          <div className="frame">
            <Image src={TimerIcon} width={110} height={110} />
            <div className="flex flex-col items-center gap-5 justify-center">
              <h2 className="titleUpgrade">
                {t.YouHaveOnly} {remainingDays} {t.days} {" "}
                {t.LoseOurFree}
              </h2>
              <p className="descriptionUpgrade">
                {t.HarryUp}
              </p>
            </div>
            <div>
              <Button onClick={() => {
                router.pathname === "/"
                  ? setTimeout(
                    () =>
                      ref.current.scrollIntoView({
                        behavior: "smooth",
                      }),
                    200
                  )
                  : (window.location.href = "/" + locale);
                closeModel();
              }}

                style={{
                  background: 'linear-gradient(to right, #4FD1C5, #E3342F)', // Teal to Rose gradient
                  borderRadius: '9999px', // Full rounded
                  padding: '0.125rem', // p-0.5 in rem units
                  color: '#38A169', // Text color
                  transition: 'border-color 0.3s ease', // Smooth transition for hover
                  borderColor: '#38A169', // Initial border color
                  ':hover': { borderColor: '#2F855A' }, // Hover border color
                  marginBottom: '20px',
                }}

              >
                <span className="flex w-full bg-gray-100 text-green rounded-full p-2 ">
                  <span className="vuesax-broken"><Image src={rankingIcon} width={20} height={20} />
                  </span>
                  {t.Upgrade}
                </span>
              </Button>
            </div>
          </div>
        </Model>

      </div>
    </nav>
  );
});

export default MainNavigation;
