import clsx from "clsx";
import Head from "next/head";
import React, { useContext, useState } from "react";
import favicon from "@/assets/images/favicon.ico";

import AppContext from "@/context/AppContext.js";
import UserContext from "@/context/UserContext.js";
import EnterpriseHeader from "../Header/EnterpriseHeader.js";
import EnterpriseSidebar from "../Sidebar/EnterpriseSidebar";


import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

const EnterpriseLayout = React.forwardRef(
  ({ usr, className, children, title, description }) => {
    const context = useContext(AppContext);
    const t = context.t;
    const locale = context.locale;
    const isArabic = locale === "ar";
    const usercontext = useContext(UserContext);

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    function parentFn(childData) {
      // handleRouteChange(childData);
      setIsMobileMenuOpen(false);
    }
    function onMobileMenuOpen(isMobileMenuOpen) {
      setIsMobileMenuOpen(isMobileMenuOpen);
    }
    return (
      <>
        <div className={clsx(className)} style={{
          direction: isArabic ? 'rtl' : 'ltr'
        }}>
          <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={description} />
            <link rel="icon" href={favicon.src} />
            <script async src={process.env.NEXT_PUBLIC_HYPERPAY_BASE_URL + `v1/paymentWidgets.js?checkoutId=` + context.hyperpayCheckout}></script>

            <script src="https://cdn.checkout.com/js/framesv2.min.js"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                (function (m, a, z, e) {
                  var s, t;
                  try {
                    t = m.sessionStorage.getItem('maze-us');
                  } catch (err) {}
              
                  if (!t) {
                    t = new Date().getTime();
                    try {
                      m.sessionStorage.setItem('maze-us', t);
                    } catch (err) {}
                  }
              
                  s = a.createElement('script');
                  s.src = z + '?t=' + t + '&apiKey=' + e;
                  s.async = true;
                  a.getElementsByTagName('head')[0].appendChild(s);
                  m.mazeUniversalSnippetApiKey = e;
                })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '53a63b6f-3e7e-40b4-bdbe-0d133727e53a');
              `,
              }}
            />
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", '${process.env.NEXT_PUBLIC_CLARITTY_ID}');
              `,
              }}
            />

            {/* Event snippet for Website lead conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                function gtag_report_conversion(url) {
                  console.log(url);
                  var callback = function () {
                    if (typeof(url) != 'undefined') {
                      window.location = url;
                    }
                  };
                  gtag('event', 'conversion', {
                    'send_to': 'AW-11039822477/SemJCJLNrcsYEI2lmZAp',
                    'event_callback': callback
                  });
                  return false;
                }
              `,
              }}
            />
          </Head>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <EnterpriseSidebar parentFn={parentFn} isMobileMenuOpen={isMobileMenuOpen} />
            <div className="flex flex-col w-full">
              <EnterpriseHeader onMobileMenuOpen={onMobileMenuOpen} isMobileMenuOpen={isMobileMenuOpen} />
              <Box component="main" className="p-5" sx={{ flexGrow: 1, p: 3 }}>
                {children}
              </Box>
            </div>
          </Box>
        </div>
      </>
    );
  }
);

export default EnterpriseLayout;
