import React from "react";

const InviteUser = ({ color }) => {
  return (
    <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 13.2083H6.87504C5.59578 13.2083 4.95614 13.2083 4.43566 13.3662C3.26379 13.7217 2.34674 14.6388 1.99126 15.8106C1.83337 16.3311 1.83337 16.9707 1.83337 18.25M17.4167 18.25V12.75M14.6667 15.5H20.1667M13.2917 5.875C13.2917 8.15317 11.4449 10 9.16671 10C6.88853 10 5.04171 8.15317 5.04171 5.875C5.04171 3.59683 6.88853 1.75 9.16671 1.75C11.4449 1.75 13.2917 3.59683 13.2917 5.875Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>  
    </svg>
  );
};

export default InviteUser;
