export default function Logo() {
  return (
    <svg
      width="89"
      height="43"
      viewBox="0 0 89 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.9996 16.0415V26.3663H28.3899V24.0491C27.4139 24.9067 26.2939 25.5851 25.082 26.0525C23.8145 26.5413 22.4663 26.7874 21.1078 26.778C19.6645 26.7892 18.2339 26.5019 16.9064 25.935C15.6294 25.3895 14.4647 24.6108 13.4716 23.64C11.945 22.1349 10.9064 20.2052 10.4905 18.1023C10.0754 15.9994 10.3018 13.8194 11.1414 11.8468C11.6929 10.5656 12.4836 9.40091 13.4716 8.41636C14.4613 7.43524 15.6268 6.64881 16.9064 6.09821C18.2339 5.53218 19.6645 5.24574 21.1078 5.25775C22.5658 5.24917 24.01 5.53562 25.3539 6.10079C26.6489 6.64538 27.8281 7.43181 28.829 8.41808C29.8127 9.40348 30.6 10.5681 31.1463 11.8486C31.7183 13.1719 32.009 14.5998 31.9996 16.0415Z"
        fill="white"
      />
      <path
        d="M20.92 10.3167C20.1841 9.57545 19.3075 8.98896 18.3416 8.59148C17.3308 8.17483 16.2469 7.96445 15.1535 7.97284V7.9821C14.0733 7.97589 13.0028 8.18627 12.0054 8.60112C11.0391 8.99783 10.1624 9.58451 9.42704 10.3263C8.69428 11.072 8.10484 11.9459 7.68818 12.9047C7.26132 13.8911 7.04102 14.9546 7.04102 16.0295C7.04102 17.1044 7.26132 18.1678 7.68818 19.1543C8.52026 21.0813 10.0638 22.6128 11.9973 23.4297C12.9908 23.8598 14.0628 24.0785 15.1454 24.072C16.2432 24.0812 17.3311 23.8626 18.3402 23.4297C19.3006 23.022 20.1755 22.4367 20.9188 21.7046C22.043 20.5699 22.8059 19.1277 23.1113 17.5599C23.4167 15.9922 23.2508 14.369 22.6348 12.8954C22.2325 11.9345 21.6505 11.0592 20.92 10.3167Z"
        fill="#F26D78"
      />
      <path
        d="M27.2702 27.5104H23.2543V24.9324C22.1686 25.8865 20.9225 26.6412 19.5744 27.1612C18.1642 27.705 16.6644 27.9788 15.1531 27.9684C13.5473 27.9808 11.9559 27.6611 10.4789 27.0305C9.05825 26.4237 7.76258 25.5573 6.65773 24.4773C4.95943 22.8028 3.80401 20.6561 3.34127 18.3167C2.87948 15.9772 3.13136 13.5519 4.06543 11.3574C4.67892 9.932 5.5586 8.63633 6.65773 7.54102C7.75876 6.44953 9.05539 5.57461 10.4789 4.96208C11.9559 4.33237 13.5473 4.0137 15.1531 4.02706C16.775 4.01752 18.3817 4.33619 19.8768 4.96494C21.3175 5.5708 22.6294 6.44571 23.7428 7.54293C24.8372 8.63919 25.7131 9.93486 26.3208 11.3593C26.9572 12.8315 27.2807 14.4201 27.2702 16.0239V27.5104ZM29.6993 2.10932C28.7166 1.12659 27.3827 0.574165 25.9917 0.574165H5.24375C3.85267 0.574165 2.51883 1.12659 1.53611 2.10932C0.552426 3.09299 0 4.42683 0 5.81791V26.5658C0 27.956 0.552426 29.2898 1.53611 30.2735C2.51883 31.2571 3.85267 31.8096 5.24375 31.8096H25.9917C27.3827 31.8096 28.7166 31.2571 29.6993 30.2735C30.683 29.2898 31.2354 27.956 31.2354 26.5658V5.81791C31.2354 4.42683 30.683 3.09299 29.6993 2.10932Z"
        fill="#F26D78"
      />
      <path
        d="M75.2002 0.521088H79.458V4.37027H88.1082V8.54523H79.458V22.9729C79.4518 23.3134 79.5153 23.6516 79.6446 23.9668C79.7737 24.2821 79.9656 24.5676 80.209 24.8062C80.459 25.0539 80.7572 25.2483 81.0848 25.3773C81.4125 25.5065 81.7631 25.5676 82.115 25.5572C82.492 25.5554 82.8634 25.4607 83.195 25.2815C83.5271 25.1023 83.8098 24.844 84.0183 24.5295C84.5419 23.8014 84.8113 22.9213 84.7848 22.025H88.9994C89.014 24.032 88.3021 25.9765 86.9959 27.5C86.3927 28.1984 85.6526 28.7659 84.8219 29.1677C83.9826 29.5771 83.0605 29.7871 82.1269 29.7811C81.2004 29.7869 80.2827 29.6037 79.4298 29.2428C77.7858 28.5508 76.4681 27.2572 75.7458 25.6263C75.3792 24.7908 75.1945 23.8871 75.2023 22.342L75.2002 0.521088Z"
        fill="#101820"
      />
      <path
        d="M46.4591 4.36395C47.9828 4.34856 49.487 4.70268 50.8435 5.39604C52.1648 6.06973 53.3215 7.02558 54.2326 8.1958C55.1648 7.0369 56.3273 6.0836 57.6463 5.39604C59.0081 4.6984 60.5194 4.34407 62.0492 4.36395C63.3638 4.35957 64.6652 4.62735 65.8714 5.15058C68.2294 6.15505 70.1115 8.02699 71.1285 10.3797C71.6586 11.5776 71.9293 12.874 71.9231 14.1838V29.4609H67.6662V14.1838C67.6737 13.4326 67.522 12.6884 67.2203 12.0003C66.9311 11.3398 66.52 10.7398 66.0081 10.2319C65.4943 9.72401 64.8906 9.31638 64.2273 9.02973C63.5336 8.73055 62.7848 8.57969 62.0294 8.58662C61.2678 8.58213 60.5133 8.7329 59.8121 9.02973C58.4609 9.59333 57.3796 10.6578 56.7952 12.0003C56.4966 12.6892 56.3463 13.4331 56.3542 14.1838V29.4609H52.1466V14.1838C52.1538 13.4327 52.0016 12.6887 51.7013 12.0003C51.4093 11.335 50.9886 10.734 50.4634 10.2319C49.9377 9.72687 49.3265 9.31984 48.658 9.02973C47.9638 8.73014 47.215 8.57918 46.4591 8.58662C45.3474 8.58213 44.2586 8.90293 43.3269 9.50954C42.3951 10.1161 41.661 10.9818 41.2152 12.0003C40.916 12.6891 40.7649 13.4329 40.7717 14.1838V29.4609H36.5695V14.1838C36.5622 12.8742 36.8317 11.5779 37.36 10.3797C38.377 8.02689 40.259 6.15485 42.6172 5.15058C43.8294 4.6246 45.138 4.35671 46.4591 4.36395Z"
        fill="#101820"
      />
      <path
        d="M1.27168 38.6537C1.03778 38.6537 0.838527 38.7373 0.673712 38.9048C0.508897 39.0696 0.426592 39.2688 0.426592 39.5027C0.426592 39.7366 0.508897 39.936 0.673712 40.1007C0.838527 40.2655 1.03778 40.3479 1.27168 40.3479C1.50558 40.3479 1.70483 40.2655 1.86965 40.1007C2.03713 39.936 2.12076 39.7366 2.12076 39.5027C2.12076 39.2662 2.03836 39.0656 1.87364 38.9008C1.70883 38.736 1.50824 38.6537 1.27168 38.6537ZM2.54336 39.5027V40.7744H2.12076V40.4476C1.88164 40.6655 1.59854 40.7744 1.27168 40.7744C0.920832 40.7744 0.620516 40.6508 0.370731 40.4037C0.123611 40.1539 0 39.8536 0 39.5027C0 39.1519 0.123611 38.853 0.370731 38.6058C0.620516 38.356 0.920832 38.2311 1.27168 38.2311C1.62253 38.2311 1.92151 38.356 2.16863 38.6058C2.41842 38.853 2.54336 39.1519 2.54336 39.5027Z"
        fill="#101820"
      />
      <path
        d="M2.98145 39.5027C2.98145 39.1519 3.10506 38.8529 3.35218 38.6058C3.5993 38.356 3.89838 38.2311 4.24913 38.2311V38.6537C4.01523 38.6537 3.81598 38.7373 3.65116 38.9048C3.48645 39.0695 3.40404 39.2688 3.40404 39.5027V40.7744H2.98145V39.5027Z"
        fill="#101820"
      />
      <path
        d="M5.7126 38.6537C5.4787 38.6537 5.27934 38.7373 5.11463 38.9048C4.94982 39.0696 4.86751 39.2688 4.86751 39.5027C4.86751 39.7366 4.94982 39.936 5.11463 40.1007C5.27934 40.2655 5.4787 40.3479 5.7126 40.3479C5.9465 40.3479 6.14575 40.2655 6.31056 40.1007C6.47804 39.936 6.56168 39.7366 6.56168 39.5027C6.56168 39.2662 6.47927 39.0656 6.31456 38.9008C6.14975 38.736 5.94916 38.6537 5.7126 38.6537ZM6.98428 39.5027V40.7744H6.56168V40.4476C6.32256 40.6655 6.03946 40.7744 5.7126 40.7744C5.36175 40.7744 5.06143 40.6508 4.81165 40.4037C4.56453 40.1539 4.44092 39.8536 4.44092 39.5027C4.44092 39.1519 4.56453 38.853 4.81165 38.6058C5.06143 38.356 5.36175 38.2311 5.7126 38.2311C6.06334 38.2311 6.36243 38.356 6.60955 38.6058C6.85933 38.853 6.98428 39.1519 6.98428 39.5027Z"
        fill="#101820"
      />
      <path
        d="M8.03007 40.1047C8.19755 40.2695 8.39813 40.3518 8.63203 40.3518C8.86593 40.3518 9.06518 40.2695 9.23 40.1047C9.39481 39.9372 9.47722 39.7366 9.47722 39.5027C9.47722 39.2688 9.39481 39.0695 9.23 38.9048C9.06518 38.74 8.86593 38.6575 8.63203 38.6575C8.39813 38.6575 8.19755 38.74 8.03007 38.9048C7.86525 39.0695 7.78295 39.2688 7.78295 39.5027C7.78295 39.7393 7.86525 39.9399 8.03007 40.1047ZM7.36035 39.5027V37.5255H7.78295V38.5539C8.0274 38.3387 8.3104 38.2311 8.63203 38.2311C8.98288 38.2311 9.28186 38.356 9.52898 38.6058C9.77877 38.8529 9.90371 39.1519 9.90371 39.5027C9.90371 39.8536 9.77877 40.1539 9.52898 40.4037C9.28186 40.6508 8.98288 40.7744 8.63203 40.7744C8.28118 40.7744 7.98097 40.6508 7.73108 40.4037C7.48396 40.1539 7.36035 39.8536 7.36035 39.5027Z"
        fill="#101820"
      />
      <path
        d="M10.3469 37.5892C10.3894 37.5467 10.4399 37.5255 10.4984 37.5255C10.5569 37.5255 10.606 37.5467 10.6458 37.5892C10.6884 37.6291 10.7096 37.6783 10.7096 37.7367C10.7096 37.7952 10.6884 37.8457 10.6458 37.8882C10.606 37.9281 10.5569 37.948 10.4984 37.948C10.4399 37.948 10.3894 37.9281 10.3469 37.8882C10.307 37.8457 10.2871 37.7952 10.2871 37.7367C10.2871 37.6783 10.307 37.6291 10.3469 37.5892ZM10.2871 38.2311H10.7097V40.7744H10.2871V38.2311Z"
        fill="#101820"
      />
      <path
        d="M12.3717 38.2311C12.6082 38.2311 12.8261 38.2921 13.0254 38.4144C13.2274 38.534 13.3829 38.6935 13.4919 38.8928H12.9657C12.8008 38.7334 12.6029 38.6537 12.3717 38.6537C12.1378 38.6537 11.9384 38.7373 11.7737 38.9048C11.6089 39.0695 11.5265 39.2688 11.5265 39.5027C11.5265 39.7366 11.6089 39.9359 11.7737 40.1007C11.9384 40.2655 12.1378 40.3479 12.3717 40.3479C12.5099 40.3479 12.6388 40.3173 12.7583 40.2562C12.8779 40.195 12.9763 40.1114 13.0534 40.005H13.5397C13.4413 40.2336 13.2859 40.4197 13.0733 40.5632C12.8633 40.704 12.6295 40.7744 12.3717 40.7744C12.0208 40.7744 11.7219 40.6508 11.4747 40.4037C11.2275 40.1539 11.104 39.8536 11.104 39.5027C11.104 39.1519 11.2275 38.853 11.4747 38.6058C11.7219 38.356 12.0208 38.2311 12.3717 38.2311Z"
        fill="#101820"
      />
      <path
        d="M17.5221 38.2311C17.756 38.2311 17.9554 38.3135 18.1201 38.4782C18.2876 38.643 18.3712 38.8423 18.3712 39.0761V40.7744H17.9487V39.0761C17.9487 38.9592 17.9062 38.8596 17.8211 38.7772C17.7387 38.6948 17.6391 38.6537 17.5221 38.6537C17.4052 38.6537 17.3056 38.6948 17.2231 38.7772C17.1407 38.8596 17.0996 38.9592 17.0996 39.0761V40.7744H16.677L16.673 39.0761C16.673 38.9592 16.6305 38.8596 16.5454 38.7772C16.4631 38.6948 16.3647 38.6537 16.2505 38.6537C16.1335 38.6537 16.0325 38.6948 15.9475 38.7772C15.8651 38.8596 15.8239 38.9592 15.8239 39.0761V40.7744H15.4014V39.0761C15.4014 38.8423 15.4838 38.643 15.6485 38.4782C15.816 38.3135 16.0167 38.2311 16.2505 38.2311C16.5056 38.2311 16.7181 38.3268 16.8883 38.518C17.0531 38.3268 17.2644 38.2311 17.5221 38.2311Z"
        fill="#101820"
      />
      <path
        d="M19.9729 38.6537C19.739 38.6537 19.5397 38.7373 19.3749 38.9048C19.2101 39.0696 19.1278 39.2688 19.1278 39.5027C19.1278 39.7366 19.2101 39.936 19.3749 40.1007C19.5397 40.2655 19.739 40.3479 19.9729 40.3479C20.2067 40.3479 20.406 40.2655 20.5708 40.1007C20.7383 39.936 20.8219 39.7366 20.8219 39.5027C20.8219 39.2662 20.7395 39.0656 20.5748 38.9008C20.41 38.736 20.2094 38.6537 19.9729 38.6537ZM21.2445 39.5027V40.7744H20.8219V40.4476C20.5828 40.6655 20.2997 40.7744 19.9729 40.7744C19.6221 40.7744 19.3217 40.6508 19.0719 40.4037C18.8248 40.1539 18.7012 39.8536 18.7012 39.5027C18.7012 39.1519 18.8248 38.853 19.0719 38.6058C19.3217 38.356 19.6221 38.2311 19.9729 38.2311C20.3236 38.2311 20.6227 38.356 20.8698 38.6058C21.1196 38.853 21.2445 39.1519 21.2445 39.5027Z"
        fill="#101820"
      />
      <path
        d="M22.8839 38.2311C23.1205 38.2311 23.3384 38.2921 23.5377 38.4144C23.7396 38.534 23.8951 38.6935 24.0041 38.8928H23.4779C23.313 38.7334 23.1151 38.6537 22.8839 38.6537C22.65 38.6537 22.4507 38.7373 22.2859 38.9048C22.1211 39.0695 22.0388 39.2688 22.0388 39.5027C22.0388 39.7366 22.1211 39.9359 22.2859 40.1007C22.4507 40.2655 22.65 40.3479 22.8839 40.3479C23.0222 40.3479 23.151 40.3173 23.2706 40.2562C23.3902 40.195 23.4885 40.1114 23.5656 40.005H24.0519C23.9537 40.2336 23.7982 40.4197 23.5855 40.5632C23.3756 40.704 23.1417 40.7744 22.8839 40.7744C22.5331 40.7744 22.2341 40.6508 21.9869 40.4037C21.7397 40.1539 21.6162 39.8536 21.6162 39.5027C21.6162 39.1519 21.7397 38.853 21.9869 38.6058C22.2341 38.356 22.5331 38.2311 22.8839 38.2311Z"
        fill="#101820"
      />
      <path
        d="M24.3779 40.7744V37.5255H24.8005V38.4104C24.9706 38.2908 25.1593 38.2311 25.3666 38.2311C25.6376 38.2311 25.8702 38.3281 26.0642 38.522C26.2582 38.7161 26.3553 38.9486 26.3553 39.2197V40.7744H25.9327V39.2197C25.9327 39.0656 25.8768 38.934 25.7652 38.825C25.6536 38.7134 25.5208 38.6575 25.3666 38.6575C25.2125 38.6575 25.0795 38.7134 24.9679 38.825C24.8563 38.934 24.8005 39.0656 24.8005 39.2197V40.7744H24.3779Z"
        fill="#101820"
      />
      <path
        d="M26.8869 37.5892C26.9294 37.5467 26.98 37.5255 27.0384 37.5255C27.0968 37.5255 27.146 37.5467 27.1859 37.5892C27.2284 37.6291 27.2496 37.6783 27.2496 37.7367C27.2496 37.7952 27.2284 37.8457 27.1859 37.8882C27.146 37.9281 27.0968 37.948 27.0384 37.948C26.98 37.948 26.9294 37.9281 26.8869 37.8882C26.847 37.8457 26.8271 37.7952 26.8271 37.7367C26.8271 37.6783 26.847 37.6291 26.8869 37.5892ZM26.8271 38.2311H27.2497V40.7744H26.8271V38.2311Z"
        fill="#101820"
      />
      <path
        d="M27.7759 40.7744V39.2197C27.7759 38.9486 27.8729 38.7161 28.0669 38.522C28.2609 38.3281 28.4935 38.2311 28.7646 38.2311C29.0382 38.2311 29.2708 38.3281 29.4622 38.522C29.6562 38.7134 29.7531 38.946 29.7531 39.2197V40.7744H29.3307V39.2197C29.3307 39.0656 29.2748 38.9326 29.1632 38.821C29.0516 38.7094 28.9187 38.6537 28.7646 38.6537C28.6104 38.6537 28.4775 38.7094 28.3659 38.821C28.2542 38.9326 28.1985 39.0656 28.1985 39.2197V40.7744H27.7759Z"
        fill="#101820"
      />
      <path
        d="M30.5862 39.2915H32.2286C32.1834 39.1055 32.0838 38.9526 31.9296 38.833C31.7782 38.7134 31.6041 38.6537 31.4074 38.6537C31.2108 38.6537 31.0354 38.7134 30.8812 38.833C30.7297 38.9526 30.6314 39.1055 30.5862 39.2915ZM30.5065 38.6058C30.7564 38.356 31.0566 38.2311 31.4074 38.2311C31.7583 38.2311 32.0573 38.356 32.3044 38.6058C32.5542 38.853 32.6791 39.152 32.6791 39.5027C32.6791 39.5693 32.6724 39.6397 32.6592 39.714H30.5862C30.6341 39.9001 30.7337 40.0529 30.8852 40.1724C31.0367 40.2894 31.2108 40.3479 31.4074 40.3479C31.6546 40.3479 31.8606 40.2589 32.0253 40.0808H32.5396C32.4333 40.2907 32.2778 40.4595 32.0731 40.587C31.8711 40.712 31.6493 40.7744 31.4074 40.7744C31.0566 40.7744 30.7564 40.6509 30.5065 40.4037C30.2594 40.1539 30.1357 39.8536 30.1357 39.5027C30.1357 39.152 30.2594 38.853 30.5065 38.6058Z"
        fill="#101820"
      />
      <path
        d="M34.8618 39.9253V38.6656H34.5469V38.243H34.8618V37.5255H35.2884V38.243H35.6193V38.6656H35.2884V39.9253C35.2884 40.0423 35.3296 40.1432 35.4121 40.2283C35.4944 40.3107 35.594 40.3519 35.7109 40.3519H35.8465V40.7744H35.7109C35.4771 40.7744 35.2764 40.692 35.109 40.5273C34.9443 40.3598 34.8618 40.1592 34.8618 39.9253Z"
        fill="#101820"
      />
      <path
        d="M36.2349 39.5027C36.2349 39.1519 36.3585 38.8529 36.6056 38.6058C36.8528 38.356 37.1517 38.2311 37.5025 38.2311V38.6537C37.2686 38.6537 37.0693 38.7373 36.9046 38.9048C36.7398 39.0695 36.6574 39.2688 36.6574 39.5027V40.7744H36.2349V39.5027Z"
        fill="#101820"
      />
      <path
        d="M38.967 38.6537C38.7331 38.6537 38.5338 38.7373 38.369 38.9048C38.2042 39.0696 38.1219 39.2688 38.1219 39.5027C38.1219 39.7366 38.2042 39.936 38.369 40.1007C38.5338 40.2655 38.7331 40.3479 38.967 40.3479C39.2009 40.3479 39.4002 40.2655 39.565 40.1007C39.7324 39.936 39.8161 39.7366 39.8161 39.5027C39.8161 39.2662 39.7337 39.0656 39.569 38.9008C39.4042 38.736 39.2036 38.6537 38.967 38.6537ZM40.2387 39.5027V40.7744H39.8161V40.4476C39.577 40.6655 39.2939 40.7744 38.967 40.7744C38.6162 40.7744 38.3158 40.6508 38.066 40.4037C37.8189 40.1539 37.6953 39.8536 37.6953 39.5027C37.6953 39.1519 37.8189 38.853 38.066 38.6058C38.3158 38.356 38.6162 38.2311 38.967 38.2311C39.3178 38.2311 39.6168 38.356 39.8639 38.6058C40.1137 38.853 40.2387 39.1519 40.2387 39.5027Z"
        fill="#101820"
      />
      <path
        d="M40.7354 40.7744V39.2197C40.7354 38.9486 40.8324 38.7161 41.0263 38.522C41.2204 38.3281 41.4529 38.2311 41.724 38.2311C41.9977 38.2311 42.2303 38.3281 42.4216 38.522C42.6157 38.7134 42.7126 38.946 42.7126 39.2197V40.7744H42.2901V39.2197C42.2901 39.0656 42.2343 38.9326 42.1226 38.821C42.011 38.7094 41.8782 38.6537 41.724 38.6537C41.5699 38.6537 41.4369 38.7094 41.3253 38.821C41.2137 38.9326 41.1578 39.0656 41.1578 39.2197V40.7744H40.7354Z"
        fill="#101820"
      />
      <path
        d="M44.6594 39.9971C44.6594 40.2097 44.5835 40.3931 44.4319 40.5473C44.2802 40.6987 44.0971 40.7744 43.8816 40.7744C43.6691 40.7744 43.4871 40.7 43.3356 40.5512C43.1841 40.3997 43.1071 40.2177 43.1045 40.0051H43.527C43.5323 40.1007 43.5682 40.1818 43.6346 40.2482C43.7038 40.3147 43.7861 40.3479 43.8816 40.3479C43.98 40.3479 44.0639 40.3147 44.133 40.2482C44.2022 40.1791 44.2368 40.0955 44.2368 39.9971C44.2368 39.9041 44.2009 39.827 44.1291 39.7658C44.0599 39.7021 43.9747 39.6502 43.8736 39.6105C43.7754 39.5679 43.6758 39.5227 43.5749 39.4749C43.4766 39.4243 43.3915 39.3512 43.3196 39.2556C43.2506 39.1573 43.216 39.0364 43.216 38.8929C43.216 38.7094 43.2811 38.554 43.4114 38.4264C43.5416 38.2961 43.6985 38.2311 43.8816 38.2311C44.0652 38.2311 44.2221 38.2961 44.3521 38.4264C44.4825 38.554 44.5476 38.7094 44.5476 38.8929H44.1211C44.1211 38.8264 44.0971 38.7706 44.0492 38.7254C44.004 38.6775 43.9481 38.6537 43.8816 38.6537C43.8153 38.6537 43.7582 38.6775 43.7104 38.7254C43.6653 38.7706 43.6426 38.8264 43.6426 38.8929C43.6426 38.9673 43.6771 39.031 43.7462 39.0842C43.818 39.1347 43.9028 39.1799 44.0013 39.2197C44.1024 39.2569 44.2022 39.3021 44.3002 39.3552C44.3986 39.4084 44.4825 39.4895 44.5516 39.5985C44.6234 39.7048 44.6594 39.8376 44.6594 39.9971Z"
        fill="#101820"
      />
      <path
        d="M45.0444 39.5027V37.5254H45.467V39.5027C45.467 39.7366 45.5494 39.9372 45.7139 40.1047C45.879 40.2694 46.078 40.3518 46.3122 40.3518V40.7744C45.9615 40.7744 45.6621 40.6508 45.4152 40.4037C45.1681 40.1539 45.0444 39.8535 45.0444 39.5027Z"
        fill="#101820"
      />
      <path
        d="M47.7229 38.6537C47.4892 38.6537 47.2896 38.7373 47.1251 38.9048C46.9602 39.0696 46.8777 39.2688 46.8777 39.5027C46.8777 39.7366 46.9602 39.936 47.1251 40.1007C47.2896 40.2655 47.4892 40.3479 47.7229 40.3479C47.957 40.3479 48.1561 40.2655 48.321 40.1007C48.4882 39.936 48.572 39.7366 48.572 39.5027C48.572 39.2662 48.4895 39.0656 48.325 38.9008C48.1601 38.736 47.9596 38.6537 47.7229 38.6537ZM48.9946 39.5027V40.7744H48.572V40.4476C48.333 40.6655 48.0501 40.7744 47.7229 40.7744C47.3721 40.7744 47.0719 40.6508 46.8223 40.4037C46.5749 40.1539 46.4512 39.8536 46.4512 39.5027C46.4512 39.1519 46.5749 38.853 46.8223 38.6058C47.0719 38.356 47.3721 38.2311 47.7229 38.2311C48.0736 38.2311 48.3729 38.356 48.6199 38.6058C48.87 38.853 48.9946 39.1519 48.9946 39.5027Z"
        fill="#101820"
      />
      <path
        d="M49.5688 39.9253V38.6656H49.2539V38.243H49.5688V37.5255H49.9954V38.243H50.3265V38.6656H49.9954V39.9253C49.9954 40.0423 50.0366 40.1432 50.1191 40.2283C50.2015 40.3107 50.3013 40.3519 50.418 40.3519H50.5536V40.7744H50.418C50.1843 40.7744 49.9834 40.692 49.8162 40.5273C49.6512 40.3598 49.5688 40.1592 49.5688 39.9253Z"
        fill="#101820"
      />
      <path
        d="M51.0686 37.5892C51.1113 37.5467 51.1613 37.5255 51.2198 37.5255C51.2784 37.5255 51.3277 37.5467 51.3675 37.5892C51.4101 37.6291 51.4314 37.6783 51.4314 37.7367C51.4314 37.7952 51.4101 37.8457 51.3675 37.8882C51.3277 37.9281 51.2784 37.948 51.2198 37.948C51.1613 37.948 51.1113 37.9281 51.0686 37.8882C51.0288 37.8457 51.0088 37.7952 51.0088 37.7367C51.0088 37.6783 51.0288 37.6291 51.0686 37.5892ZM51.0088 38.2311H51.4314V40.7744H51.0088V38.2311Z"
        fill="#101820"
      />
      <path
        d="M53.681 38.9048C53.5161 38.7373 53.3165 38.6537 53.0828 38.6537C52.8491 38.6537 52.6482 38.7373 52.4811 38.9048C52.3161 39.0696 52.2336 39.2688 52.2336 39.5027C52.2336 39.7366 52.3161 39.936 52.4811 40.1007C52.6482 40.2655 52.8491 40.3479 53.0828 40.3479C53.3165 40.3479 53.5161 40.2655 53.681 40.1007C53.8482 39.936 53.9319 39.7366 53.9319 39.5027C53.9319 39.2688 53.8482 39.0696 53.681 38.9048ZM52.1818 38.6058C52.4319 38.356 52.7321 38.2311 53.0828 38.2311C53.4336 38.2311 53.7324 38.356 53.9799 38.6058C54.2296 38.853 54.3546 39.1519 54.3546 39.5027C54.3546 39.8536 54.2296 40.1539 53.9799 40.4037C53.7324 40.6508 53.4336 40.7744 53.0828 40.7744C52.7321 40.7744 52.4319 40.6508 52.1818 40.4037C51.9347 40.1539 51.811 39.8536 51.811 39.5027C51.811 39.1519 51.9347 38.853 52.1818 38.6058Z"
        fill="#101820"
      />
      <path
        d="M54.7246 40.7744V39.2197C54.7246 38.9486 54.8218 38.7161 55.016 38.522C55.2097 38.3281 55.4425 38.2311 55.7135 38.2311C55.9871 38.2311 56.2198 38.3281 56.411 38.522C56.6052 38.7134 56.7019 38.946 56.7019 39.2197V40.7744H56.2797V39.2197C56.2797 39.0656 56.2238 38.9326 56.1121 38.821C56.0004 38.7094 55.8678 38.6537 55.7135 38.6537C55.5591 38.6537 55.4266 38.7094 55.3148 38.821C55.2031 38.9326 55.1472 39.0656 55.1472 39.2197V40.7744H54.7246Z"
        fill="#101820"
      />
      <path
        d="M59.2462 40.1047C59.4134 40.2695 59.6143 40.3518 59.848 40.3518C60.0816 40.3518 60.2811 40.2695 60.4461 40.1047C60.6106 39.9372 60.693 39.7366 60.693 39.5027C60.693 39.2688 60.6106 39.0695 60.4461 38.9048C60.2811 38.74 60.0816 38.6575 59.848 38.6575C59.6143 38.6575 59.4134 38.74 59.2462 38.9048C59.0813 39.0695 58.9988 39.2688 58.9988 39.5027C58.9988 39.7393 59.0813 39.9399 59.2462 40.1047ZM58.5762 39.5027V37.5255H58.9988V38.5539C59.2435 38.3387 59.5264 38.2311 59.848 38.2311C60.1987 38.2311 60.4975 38.356 60.745 38.6058C60.9946 38.8529 61.1196 39.1519 61.1196 39.5027C61.1196 39.8536 60.9946 40.1539 60.745 40.4037C60.4975 40.6508 60.1987 40.7744 59.848 40.7744C59.4972 40.7744 59.197 40.6508 58.9469 40.4037C58.6999 40.1539 58.5762 39.8536 58.5762 39.5027Z"
        fill="#101820"
      />
      <path
        d="M62.3623 40.9451C62.2744 41.1817 62.1308 41.3743 61.9317 41.5232C61.7348 41.672 61.5144 41.757 61.2701 41.7783V41.3517C61.4267 41.3304 61.5676 41.2707 61.6927 41.1724C61.8173 41.0767 61.9077 40.9531 61.9637 40.8016L62.0714 40.5066L61.2422 38.2304H61.6927L62.2984 39.8887L62.9046 38.2304H63.3546L62.3623 40.9451Z"
        fill="#101820"
      />
      <path
        d="M65.6909 36.6141H66.2767V37.4385H67.4673V38.013H66.2767V39.9118C66.2762 39.9586 66.2847 40.0053 66.3024 40.0486C66.3202 40.092 66.3467 40.1313 66.3804 40.1641C66.4146 40.1983 66.4559 40.225 66.5007 40.2428C66.5459 40.2605 66.5942 40.269 66.6425 40.2676C66.6944 40.2673 66.7454 40.2541 66.791 40.2294C66.8368 40.2047 66.8758 40.1692 66.9046 40.1258C66.9765 40.0257 67.0137 39.9045 67.0101 39.7812H67.5901C67.5919 40.0574 67.4939 40.325 67.3143 40.5346C67.2314 40.6308 67.1294 40.7089 67.015 40.7642C66.8993 40.8205 66.7725 40.8494 66.6439 40.8486C66.5166 40.8494 66.3902 40.8241 66.2727 40.7745C66.0465 40.6793 65.8652 40.5013 65.7659 40.2768C65.7153 40.1618 65.69 40.0374 65.6909 39.9118V36.6141Z"
        fill="#101820"
      />
      <path
        d="M69.6144 37.9256C69.4557 37.9247 69.2982 37.9556 69.1515 38.0166C69.0096 38.075 68.8806 38.1613 68.7723 38.2703C68.6659 38.3798 68.5803 38.5078 68.5201 38.648C68.457 38.793 68.4248 38.9495 68.4248 39.1075C68.4248 39.2656 68.457 39.4219 68.5201 39.5669C68.6425 39.8502 68.8691 40.0754 69.1532 40.1956C69.2992 40.2588 69.457 40.2909 69.6162 40.29C69.7776 40.2913 69.9372 40.2592 70.0858 40.1956C70.2273 40.1357 70.3558 40.0496 70.465 39.9419C70.6295 39.7749 70.7408 39.5628 70.7851 39.3326C70.8294 39.1024 70.8046 38.8642 70.7137 38.648C70.6547 38.5068 70.5691 38.378 70.4618 38.2689C70.3536 38.1599 70.2246 38.0737 70.0826 38.0152C69.9341 37.954 69.7749 37.923 69.6144 37.9243V37.9256ZM69.6144 37.3441C69.8525 37.3428 70.0889 37.3897 70.3089 37.482C70.5208 37.5712 70.7137 37.6998 70.8773 37.8611C71.0387 38.0222 71.1682 38.2126 71.2582 38.4223C71.3518 38.6388 71.3992 38.8724 71.3975 39.1082V40.7971H70.8055V40.4179C70.6459 40.5582 70.4627 40.6693 70.2645 40.7457C70.057 40.8257 69.8365 40.8658 69.6144 40.8642C69.3781 40.8661 69.1444 40.8191 68.9271 40.7263C68.7183 40.6372 68.5276 40.5099 68.3653 40.351C68.203 40.1919 68.0735 40.0027 67.9839 39.794C67.8922 39.5771 67.8447 39.3439 67.8447 39.1082C67.8447 38.8725 67.8922 38.6392 67.9839 38.4223C68.0744 38.2127 68.2035 38.0223 68.3653 37.8611C68.5272 37.7006 68.7178 37.5721 68.9271 37.482C69.1444 37.3892 69.3781 37.3423 69.6144 37.3441Z"
        fill="#101820"
      />
      <path
        d="M72.6405 40.8037H72.0547V39.1149C72.0534 38.877 72.1044 38.6416 72.2042 38.4256C72.3012 38.2131 72.4405 38.0226 72.613 37.8651C72.9797 37.5317 73.4582 37.3482 73.9535 37.3512V37.9316C73.7792 37.9304 73.6063 37.9614 73.4431 38.023C73.2892 38.081 73.1473 38.1669 73.0245 38.2762C72.9056 38.3824 72.8099 38.5114 72.7421 38.6555C72.6737 38.7991 72.6382 38.9561 72.6387 39.1152L72.6405 40.8037Z"
        fill="#101820"
      />
      <path
        d="M73.6191 43V42.4255H73.9756C74.0999 42.4292 74.2222 42.3912 74.3225 42.3175C74.3658 42.2885 74.4018 42.2494 74.4266 42.2035C74.4519 42.1575 74.4653 42.1062 74.4657 42.0539V37.3437H75.0514V42.0539C75.0523 42.1816 75.023 42.3078 74.9663 42.4223C74.91 42.5369 74.8315 42.6391 74.7352 42.7229C74.6346 42.81 74.5193 42.8788 74.3951 42.9259C74.2612 42.976 74.1193 43.0011 73.9761 43H73.6191Z"
        fill="#101820"
      />
      <path
        d="M77.342 37.9256C77.1832 37.9247 77.0259 37.9556 76.879 38.0166C76.7371 38.075 76.6081 38.1613 76.4999 38.2703C76.3927 38.38 76.3061 38.5084 76.245 38.6494C76.182 38.7945 76.1496 38.9508 76.1496 39.1088C76.1496 39.2669 76.182 39.4232 76.245 39.5683C76.3669 39.8515 76.594 40.0767 76.8777 40.1969C77.024 40.2602 77.1815 40.2924 77.3406 40.2914C77.5021 40.2928 77.6622 40.2606 77.8107 40.1969C77.9516 40.137 78.0803 40.051 78.1898 39.9433C78.3548 39.7765 78.467 39.5644 78.5122 39.3339C78.557 39.1034 78.5326 38.8647 78.4421 38.648C78.3827 38.5068 78.2976 38.378 78.1898 38.2689C78.0816 38.1599 77.9531 38.0737 77.8107 38.0152C77.6622 37.954 77.503 37.9231 77.342 37.9243V37.9256ZM77.342 37.3441C77.5806 37.3428 77.8168 37.3898 78.0368 37.482C78.2484 37.5712 78.4413 37.6998 78.6048 37.8611C78.7659 38.0224 78.8948 38.2128 78.984 38.4223C79.0776 38.6388 79.125 38.8724 79.1237 39.1082V40.7971H78.5335V40.4179C78.3738 40.5582 78.1908 40.6693 77.992 40.7457C77.785 40.8257 77.5641 40.8658 77.342 40.8642C77.106 40.8661 76.8719 40.8191 76.6546 40.7263C76.4458 40.6372 76.2556 40.5099 76.0933 40.351C75.931 40.1919 75.8016 40.0025 75.712 39.7937C75.6197 39.5767 75.5723 39.3436 75.5723 39.1078C75.5723 38.8722 75.6197 38.6389 75.712 38.4219C75.802 38.2123 75.9314 38.022 76.0933 37.8608C76.2547 37.7003 76.4453 37.5717 76.6546 37.4817C76.8719 37.389 77.106 37.3422 77.342 37.3441Z"
        fill="#101820"
      />
      <path
        d="M81.147 37.3508C81.3567 37.3487 81.5639 37.3975 81.7505 37.4929C81.9323 37.5857 82.0915 37.7172 82.217 37.8782C82.3451 37.7187 82.5052 37.5875 82.687 37.4929C82.8742 37.3969 83.0821 37.3481 83.2928 37.3508C83.4737 37.3502 83.6528 37.387 83.8187 37.4591C84.1432 37.5973 84.4026 37.8549 84.5423 38.1787C84.6155 38.3436 84.6527 38.522 84.6519 38.7023V40.8047H84.0656V38.7023C84.0669 38.599 84.0457 38.4965 84.0044 38.4017C83.9645 38.3108 83.9082 38.2283 83.8378 38.1584C83.7668 38.0885 83.6838 38.0325 83.5925 37.993C83.4972 37.9518 83.3938 37.931 83.2901 37.9319C83.1849 37.9313 83.0812 37.9521 82.985 37.993C82.7987 38.0705 82.6502 38.2169 82.5696 38.4017C82.5288 38.4966 82.5079 38.599 82.5088 38.7023V40.8047H81.9301V38.7023C81.931 38.599 81.9102 38.4965 81.8685 38.4017C81.8285 38.3102 81.7705 38.2275 81.6981 38.1584C81.6259 38.0889 81.5416 38.0329 81.4499 37.993C81.3546 37.9517 81.2512 37.931 81.147 37.9319C81.0419 37.9313 80.9377 37.952 80.8406 37.993C80.6548 38.0705 80.5058 38.2169 80.4255 38.4017C80.3843 38.4966 80.3635 38.599 80.3643 38.7023V40.8047H79.7861V38.7023C79.7852 38.5221 79.8225 38.3436 79.8952 38.1787C80.0349 37.8549 80.2943 37.5973 80.6189 37.4591C80.7856 37.3867 80.9657 37.3498 81.147 37.3508Z"
        fill="#101820"
      />
      <path
        d="M88.4396 40.1595H88.0094V39.8818C87.8933 39.9842 87.7603 40.0654 87.6162 40.1216C87.4654 40.1801 87.3054 40.2095 87.144 40.2083C86.9724 40.2095 86.802 40.175 86.6442 40.1067C86.4925 40.0413 86.3542 39.9483 86.2362 39.8323C86.1183 39.7156 86.0243 39.577 85.9595 39.4243C85.8926 39.2653 85.858 39.0947 85.858 38.9224C85.858 38.75 85.8926 38.5793 85.9595 38.4204C86.0912 38.1116 86.3374 37.8655 86.6459 37.7336C86.8039 37.6654 86.9741 37.631 87.1457 37.6323C87.3195 37.6311 87.492 37.6656 87.6516 37.7336C87.9625 37.864 88.2099 38.1104 88.342 38.4204C88.4095 38.579 88.444 38.7498 88.4432 38.9222L88.4396 40.1595ZM88.4543 37.0927H85.8735C85.729 37.0932 85.5906 37.1509 85.4882 37.2531C85.3862 37.3554 85.3285 37.494 85.3281 37.6385V40.2192C85.3285 40.3637 85.3862 40.5023 85.4882 40.6044C85.5906 40.7066 85.729 40.7642 85.8735 40.7646H88.4543C88.5988 40.7642 88.7376 40.7066 88.8396 40.6044C88.9421 40.5023 88.9997 40.3638 89.0001 40.2192V37.6378C88.9997 37.4934 88.9416 37.3549 88.8396 37.2529C88.7372 37.1507 88.5988 37.0932 88.4543 37.0927Z"
        fill="#101820"
      />
      <path
        d="M87.7894 38.3065C87.7105 38.2258 87.6156 38.1617 87.5114 38.1184C87.4015 38.0727 87.2839 38.0496 87.1651 38.0505C87.048 38.05 86.9318 38.0731 86.8241 38.1184C86.7194 38.1617 86.6249 38.2257 86.5456 38.3065C86.4667 38.388 86.4028 38.4831 86.3576 38.5874C86.311 38.6951 86.2871 38.8112 86.2871 38.9286C86.2871 39.0459 86.311 39.162 86.3576 39.2698C86.4472 39.4795 86.6144 39.6467 86.8241 39.7362C86.9318 39.7833 87.048 39.8073 87.1655 39.8066C87.2844 39.8075 87.4023 39.7836 87.5118 39.7362C87.6156 39.6915 87.7101 39.6278 87.7898 39.5481C87.9118 39.4239 87.9943 39.2665 88.0271 39.0956C88.0604 38.9248 88.0422 38.748 87.9756 38.5874C87.9318 38.4829 87.8687 38.3874 87.7894 38.3065Z"
        fill="#101820"
      />
    </svg>
  );
}
