import { isMobile } from 'react-device-detect';
import styles from "../../../styles/Home.module.scss";
import { useContext, useEffect, useRef, useState } from "react";

// Module
import AppContext from "@/context/AppContext";
import { useAuth } from "@/common/hooks/auth";
import TranslationContext from "@/context/TranslationContext";
import TranslationWidget from "@/modules/TranslationWidget/TranslationWidget";
import EnterpriseLayout from "@/common/components/elements/Layout/EnterpriseLayout.js";

export default function Home() {
  const { user } = useAuth({
    middleware: "auth",
  });
  const appcontext = useContext(AppContext);
  const t = appcontext.t;
  const [limitExceed, setLimitExceed] = useState(false);
  const [docLimitExceed, setDocLimitExceed] = useState(false);
  const [docLimitExceedMsg, setDocLimitExceedMsg] = useState(false);

  const [limitExceedType, setLimitExceedType] = useState(0);
  const [limitExceedMsg, setLimitExceedMsg] = useState('');

  const [allTranslate, setAllTranslate] = useState(false);

  const [errorTranslate, setErrorTranslate] = useState(false);

  const [allTranslatedDocuments, setAllTranslatedDocuments] = useState([]);

  const [docs, setDocs] = useState([]);
  const [displayDocs, setDisplayDocs] = useState([]);

  const [model, setModel] = useState("en-ar");
  const [translations, setTranslations] = useState([]);

  const [lLang, setLLang] = useState({ lang: t.English, model: "en" });
  const [rLang, setRLang] = useState({ lang: t.Arabic, model: "ar" });

  return (
    <EnterpriseLayout
      usr={user}
      className="mb-[50px] md:mb-[140px] lg:mb-[160px] xl:mb-[175px]"
      title="Tarjama AMT - The best Arabic translation tool"
      description="Best online translator - translate your text and document with Tarjama translator"
    >
      <div className={`${styles.container}`}>
        <TranslationContext.Provider
          value={{
            docs: docs,
            setDocs: setDocs,
            displayDocs: displayDocs,
            setDisplayDocs: setDisplayDocs,
            allTranslate: allTranslate,
            setAllTranslate: setAllTranslate,
            errorTranslate: errorTranslate,
            setErrorTranslate: setErrorTranslate,
            model: model,
            setModel: setModel,
            setLLang: setLLang,
            setRLang: setRLang,
            rLang: rLang,
            lLang: lLang,
            translations: translations,
            setTranslations: setTranslations,
            setLimitExceed: setLimitExceed,
            limitExceed: limitExceed,
            limitExceedType: limitExceedType,
            setLimitExceedType: setLimitExceedType,
            limitExceedMsg: limitExceedMsg,
            setLimitExceedMsg: setLimitExceedMsg,
            setDocLimitExceed: setDocLimitExceed,
            setDocLimitExceedMsg: setDocLimitExceedMsg,
            docLimitExceedMsg: docLimitExceedMsg,
            docLimitExceed: docLimitExceed,
            setAllTranslatedDocuments: setAllTranslatedDocuments,
            allTranslatedDocuments: allTranslatedDocuments,
          }}
        >
          <TranslationWidget />
        </TranslationContext.Provider>
      </div>
    </EnterpriseLayout>
  );
}
