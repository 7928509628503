const GogoleAnalytics = () => {
  const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
  const url = "https://www.googletagmanager.com/ns.html?id=" + GTM_ID;
  return (
    <noscript>
      <iframe
        src={url}
        height="0"
        width="0"
        style={{ display: "none", visibility: "hidden" }}
      ></iframe>
    </noscript>
  );
};

export default GogoleAnalytics;
