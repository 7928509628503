import clsx from "clsx";
import Head from "next/head";
import Header from "../Header/Header.js";
import Footer from "../Footer/Footer.js";
import { isMobile } from 'react-device-detect';
import favicon from "@/assets/images/favicon.ico";
import TopNotificationBar from "../TopNotificationBar/TopNotificationBar.js";

import {
  USER_BILLING_CARD_URL,
  T_WEBINAR_LINK,
  T_WEBINAR_LINK_AR,
} from "@/common/utils/constant.js";
import React, { useContext } from "react";
import AppContext from "@/context/AppContext.js";
import UserContext from "@/context/UserContext.js";
import GoogleAnalytics from "../GoogleAnalytics/index.js";

const Layout = React.forwardRef(
  ({ usr, className, children, title, description }, ref) => {
    const context = useContext(AppContext);
    const t = context.t;
    const locale = context.locale;

    const usercontext = useContext(UserContext);
    const setCapturePendingInvoice = usercontext.setCapturePendingInvoice;


    const { topWebinarTextEn, topWebinarTextAr, topWebinarLinkEn, topWebinarLinkAr } = useContext(AppContext);


    function removeHTMLTagsAndEntities(str) {
      // Remove HTML tags
      const withoutTags = str.replace(/<\/?[^>]+(>|$)/g, "");

      // Remove HTML entities like &nbsp;
      const withoutEntities = withoutTags.replace(/&[^;]+;/g, "");

      return withoutEntities;
    }

    // Assuming 'locale' is set to either 'en' or 'ar'
    const notification = {
      text: locale === 'ar' ? removeHTMLTagsAndEntities(topWebinarTextAr) : removeHTMLTagsAndEntities(topWebinarTextEn),
      link: locale === 'ar' ? topWebinarLinkAr : topWebinarLinkEn,
      btnText: t.ClickHere, // Assuming 't.ClickHere' is coming from your translation logic
    };


    const pending_payment_notification = {
      text: t.pendingPaymentNotificationText,
      link: USER_BILLING_CARD_URL,
      btnText: t.paymentManagement,
    };

    return (
      <>
        <TopNotificationBar
          key="top-notification"
          notification={notification}
        />
        <div className="bg-blue"></div>
        {usr?.pending_payment && (
          <TopNotificationBar
            key="top-notification"
            notification={pending_payment_notification}
            backgroundColor="blue"
            state={setCapturePendingInvoice}
          />
        )}
        <div className={clsx(className)}>
          <Head>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="og:title" content={title} />
            <meta name="og:description" content={description} />
            <link rel="icon" href={favicon.src} />
            <script async src={process.env.NEXT_PUBLIC_HYPERPAY_BASE_URL + `v1/paymentWidgets.js?checkoutId=` + context.hyperpayCheckout}></script>

            <script src="https://cdn.checkout.com/js/framesv2.min.js"></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                (function (m, a, z, e) {
                  var s, t;
                  try {
                    t = m.sessionStorage.getItem('maze-us');
                  } catch (err) {}
              
                  if (!t) {
                    t = new Date().getTime();
                    try {
                      m.sessionStorage.setItem('maze-us', t);
                    } catch (err) {}
                  }
              
                  s = a.createElement('script');
                  s.src = z + '?t=' + t + '&apiKey=' + e;
                  s.async = true;
                  a.getElementsByTagName('head')[0].appendChild(s);
                  m.mazeUniversalSnippetApiKey = e;
                })(window, document, 'https://snippet.maze.co/maze-universal-loader.js', '53a63b6f-3e7e-40b4-bdbe-0d133727e53a');
              `,
              }}
            />
            {/* Global Site Tag (gtag.js) - Google Analytics */}
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
          `,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
              (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", '${process.env.NEXT_PUBLIC_CLARITTY_ID}');
              `,
              }}
            />

            {/* Event snippet for Website lead conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
                function gtag_report_conversion(url) {
                  console.log(url);
                  var callback = function () {
                    if (typeof(url) != 'undefined') {
                      window.location = url;
                    }
                  };
                  gtag('event', 'conversion', {
                    'send_to': 'AW-11039822477/SemJCJLNrcsYEI2lmZAp',
                    'event_callback': callback
                  });
                  return false;
                }
              `,
              }}
            />
          </Head>
          <Header ref={ref} user={usr} />
          <main
            className={`${locale == "ar" ? "font-urwarabic" : ""
              }`}
          >
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  var wpwlOptions = {
                    paymentTarget:"_top",
                  }
                `
              }} />
            <GoogleAnalytics />
            {children}
          </main>
        </div>
        <Footer />
      </>
    );
  }
);

export default Layout;
