import { useContext, useEffect, useRef, useState } from "react";
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Popover from '@mui/material/Popover';
import Skeleton from '@mui/material/Skeleton';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// Module
import axios from "@/common/libs/axios";
import { useAuth } from "@/common/hooks/auth";
import AppContext from "@/context/AppContext";
import EditIcon from "@/assets/icons/EditIcon";
import UserContext from "@/context/UserContext";
import InviteUserIcon from "@/assets/icons/InviteUser";
import Toast from "@/common/components/elements/ToastPopup/ToastPopup";
import EnterpriseLayout from "@/common/components/elements/Layout/EnterpriseLayout.js";
import EditTeamMember from "@/common/components/elements/Forms/EnterpriseTeamForm/EditTeamMember";
import DeleteTeamMember from "@/common/components/elements/Forms/EnterpriseTeamForm/DeleteTeamMember";
import InviteTeamMember from "@/common/components/elements/Forms/EnterpriseTeamForm/InviteTeamMember";


export default function Team() {
  const usercontext = useContext(UserContext);
  const appcontext = useContext(AppContext);
  const user = usercontext.user;
  const cardRef = useRef();
  const t = appcontext.t;
  const locale = appcontext.locale;
  const isArabic = locale === "ar";

  const [open, setOpen] = useState(false);
  const [toastMsg, seToastMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [team, setTeam] = useState([1, 2, 3, 4, 5]);
  const [selectedMemberData, setSelectedMemberData] = useState(null);
  const [showToastMsg, setShowToastMsg] = useState(false);

  const handleToastClose = () => {
    setOpen(false);
  };

  const onCloseDialog = () => {
    setModalType("");
    setShowModal(false);
  };

  const onDeleteMember = () => {
    setLoading(true);
    const id = selectedMemberData.user_id ? selectedMemberData.user_id : selectedMemberData.id;
    const api = `/api/user/team/users/${selectedMemberData.id}/delete`;
    const deletedIndex = team.findIndex(teamMember => teamMember.id === selectedMemberData.id);
    axios
      .delete(api)
      .then((res) => {
        team.splice(deletedIndex, 1)
        setTeam(team);
        setModalType("");
        setShowModal(false);
        setLoading(false);
        setOpen(true);
        seToastMsg('Member deleted succesfully');
      })
      .catch((error) => {
        setModalType("");
        setShowModal(false);
        setLoading(false);
        setOpen(true);
        seToastMsg(error?.response.data.error || 'Failed to delete member');
        if (error?.response?.status !== 409) throw error;
      })
  };

  const updateMember = (selectedMemberData) => {
    setLoading(true);
    const api = `/api/user/team/users/${selectedMemberData.user_id}/update`;
    const updatedIndex = team.findIndex(teamMember => teamMember.user_id === selectedMemberData.user_id);
    axios
      .post(api, selectedMemberData)
      .then((res) => {
        team[updatedIndex].num_files_per_user = selectedMemberData.num_files_per_user;
        team[updatedIndex].num_words_per_user_document = selectedMemberData.num_words_per_user_document;
        team[updatedIndex].num_char_per_user_text_translation = selectedMemberData.num_char_per_user_text_translation;
        team[updatedIndex].num_words_per_user_text_translation = selectedMemberData.num_words_per_user_text_translation;
        team[updatedIndex].num_pages_per_user_website_extraction = selectedMemberData.num_pages_per_user_website_extraction;
        setTeam(team);
        setModalType("");
        setShowModal(false);
        setLoading(false);
        setOpen(true);
        seToastMsg('Member updated succesfully');
      })
      .catch((error) => {
        setModalType("");
        setShowModal(false);
        setLoading(false);
        setOpen(true);
        seToastMsg(error?.response.data.error || 'Failed to updated member');
        if (error?.response?.status !== 409) throw error;
      })
  };

  const onShowModal = (type, data = null) => {
    setModalType(type);
    setShowModal(true);
    setSelectedMemberData(data);
  };

  const [selectedLimitation, setSelectedLimitation] = useState({
    num_files_per_user_calc: "",
    num_words_per_user_document_calc: "",
    num_words_per_user_text_translation_calc: "",
    num_pages_per_user_website_extraction_calc: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event, memberData) => {
    setSelectedLimitation(memberData);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onInviteMember = (data) => {
    axios
      .post("/api/user/team-members", data)
      .then((res) => {
        setOpen(true);
        if (res?.data?.user) {
          res.data.user.num_files_per_user_percentage = "0%";
          res.data.user.num_words_per_user_text_translation_percentage = "0%";
          res.data.user.num_words_per_user_document_percentage = "0%";
          res.data.user.num_pages_per_user_website_extraction_percentage = "0%";
          res.data.user.num_char_per_user_text_translation_percentage = "0%";
        }
        team.push(res?.data?.user);
        setTeam(team);
        seToastMsg('Invitation sent succesfully');
        onCloseDialog();
      })
      .catch((error) => {
        setOpen(true);
        onCloseDialog();
        seToastMsg(error?.response.data.message);
        if (error?.response?.status !== 409) throw error;
      });
  }
  const openpopover = Boolean(anchorEl);
  useEffect(() => {
    axios
      .get("/api/user/team/users")
      .then((res) => {
        res.data.users.forEach(element => {
          if (element.user_id) {
            element.id = element.user_id;
          }
          element.num_files_per_user_percentage = element.num_files_per_user_percentage || "0%";
          element.num_words_per_user_text_translation_percentage = element.num_words_per_user_text_translation_percentage || "0%";
          element.num_words_per_user_document_percentage = element.num_words_per_user_document_percentage || "0%";
          element.num_pages_per_user_website_extraction_percentage = element.num_pages_per_user_website_extraction_percentage || "0%";
          element.num_char_per_user_text_translation_percentage = element.num_char_per_user_text_translation_percentage || "0%";
        });
        setTeam(res.data.users);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setTeam([]);
        if (error?.response?.status !== 409) throw error;
      })
  }, []);

  return (
    <EnterpriseLayout
      usr={user}
      className="mb-[50px] md:mb-[140px] lg:mb-[160px] xl:mb-[175px]"
      title="Tarjama AMT - The best Arabic translation tool"
      description="Best online translator - translate your text and document with Tarjama translator"
    >
      <Toast open={open} toastMsg={toastMsg} handleClose={handleToastClose} />
      <div>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={openpopover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <div className="w-full flex gap-0 flex-wrap md:flex-nowrap flex-col p-3 w-72">
            <div className="flex justify-between w-full mb-2">
              <div className="flex flex-col w-1/2">
                <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-lightblue ${isArabic ? 'ml-1' : 'mr-1'}`} />{t.Files}</p>
                <p className="text-black font-semibold text-sm">{selectedLimitation?.num_files_per_user_calc}</p>
              </div>
              <div className="flex flex-col w-1/2 items-end md:items-start">
                <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-green ${isArabic ? 'ml-1' : 'mr-1'}`} />{t.TextWords}</p>
                <p className="text-black font-semibold text-sm">{selectedLimitation?.num_words_per_user_text_translation_calc}</p>
              </div>
            </div>
            <div className="flex justify-between w-full mb-2">
              <div className="flex flex-col w-1/2">
                <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-yellow ${isArabic ? 'ml-1' : 'mr-1'}`} /><span>{t.DocumentWords}</span></p>
                <p className="text-black font-semibold text-sm">{selectedLimitation?.num_words_per_user_document_calc}</p>
              </div>
              <div className="flex flex-col w-1/2 items-end md:items-start">
                <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-red ${isArabic ? 'ml-1' : 'mr-1'}`} />{t.Pages}</p>
                <p className="text-black font-semibold text-sm">{selectedLimitation?.num_pages_per_user_website_extraction_calc}</p>
              </div>
            </div>
            <div className="flex justify-between w-full">
              <div className="flex flex-col w-1/2">
                <p className="text-grey800 flex items-center text-sm"><div className={`h-3 w-3 rounded-full bg-pink-500 ${isArabic ? 'ml-1' : 'mr-1'}`} /><span>{t.DocumentsChars}</span></p>
                <p className="text-black font-semibold text-sm">{selectedLimitation?.num_char_per_user_text_translation_calc}</p>
              </div>
            </div>
          </div>
        </Popover>
      </div>
      <div className="flex justify-between items-center mb-20">
        <p className="font-semibold text-lg">{t.MyTeam}</p>
        <Button variant="outlined"
          startIcon={<InviteUserIcon color={loading ? "grey" : "#00A499"} />}
          className="btn-outline-green"
          disabled={loading}
          onClick={() => onShowModal("inviteTeamMember")}>
          <span className={`${isArabic ? 'mr-5' : 'ml-5'}`}>{t.InviteMember}</span>
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align={isArabic ? "rigt" : "left"}>{t.Name}</TableCell>
              <TableCell align={isArabic ? "rigt" : "left"}>{t.Email}</TableCell>
              <TableCell align={isArabic ? "rigt" : "left"}>{t.Limitation}</TableCell>
              <TableCell align="center">{t.Actions}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {team.map((memberData, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" align={isArabic ? "rigt" : "left"}>
                  {loading ?
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                    <div className="flex items-center">
                      <Tooltip title={memberData.user_id ? t.Active : t.Pending}>
                        <div className={`rounded-full w-3 h-3 mr-5 ${memberData.user_id ? 'bg-secoundaryGreen' : 'bg-yellow'}`}></div>
                      </Tooltip>
                      <p className="font-semibold capitalize">{memberData.name}</p>
                    </div>
                  }
                </TableCell>
                <TableCell align={isArabic ? "rigt" : "left"}>
                  {loading ?
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                    <p className="font-semibold">{memberData.email}</p>
                  }
                </TableCell>
                <TableCell align={isArabic ? "rigt" : "left"}>
                  {loading ?
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> :
                    <div className="rounded-full w-full h-3"
                      onMouseEnter={(e) => {
                        handlePopoverOpen(e, memberData);
                      }}
                      onMouseLeave={handlePopoverClose}
                      style={{
                        background: `linear-gradient(to ${isArabic ? 'left' : 'right'},    
                        #3CB9D6 ${memberData.num_files_per_user_percentage},   
                         #00A499 ${memberData.num_files_per_user_percentage} ${memberData.num_words_per_user_text_translation_percentage},    
                         #FBC46E ${memberData.num_words_per_user_text_translation_percentage}  ${memberData.num_words_per_user_document_percentage},    
                         #F16D78 ${memberData.num_words_per_user_document_percentage} ${memberData.num_pages_per_user_website_extraction_percentage}, 
                         #ec489d ${memberData.num_pages_per_user_website_extraction_percentage} ${memberData.num_char_per_user_text_translation_percentage}, 
                         #D1D2D4 ${memberData.num_char_per_user_text_translation_percentage})`
                      }} />
                  }
                </TableCell>
                <TableCell align="center">
                  <div className="flex items-center justify-center w-full" >
                    {loading ?
                      <>
                        <Skeleton variant="rectangular" width={20} height={20} className="mr-5" />
                        <Skeleton variant="rectangular" width={20} height={20} />
                      </>
                      :
                      <>
                        <Tooltip title={t.Delete}>
                          <IconButton onClick={() => onShowModal("deleteTeamMember", memberData)} disabled={memberData.team_admin}>
                            <DeleteOutlineOutlinedIcon color={memberData.team_admin ? "grey" : "error"} className="mr-5 cursor-pointer" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t.Edit}>
                          <IconButton onClick={() => onShowModal("editTeamMember", memberData)} disabled={!memberData.user_id || memberData.team_admin}>
                            <EditIcon className="cursor-pointer" color={!memberData.user_id || memberData.team_admin ? "grey" : "black"} />
                          </IconButton>
                        </Tooltip>
                      </>
                    }
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {modalType === "deleteTeamMember" && <DeleteTeamMember onCloseDialog={onCloseDialog} onDeleteMember={onDeleteMember} />}
      {modalType === "inviteTeamMember" && <InviteTeamMember onCloseDialog={onCloseDialog} onInviteMember={onInviteMember} />}
      {modalType === "editTeamMember" && <EditTeamMember onCloseDialog={onCloseDialog} selectedMemberData={selectedMemberData} updateMember={updateMember} />}
    </EnterpriseLayout>
  );
}