// Modal.js
import React from 'react';
import Image from "next/image";
import Button from "../Button/Button";
import CloseIcon from "@/assets/images/close.png";

const Model = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-flex' : 'modal display-none';

  return (
    <div className={showHideClassName}>
        
      <section className="modal-main rounded pt-5 pr-5">
        <button  type="button" onClick={handleClose}>
        <Image src={CloseIcon} width={25} height={25} />
        </button>
        <div className="modal-content">{children}</div>
        
      </section>
      
    </div>
  );
};

export default Model;
